@charset "UTF-8";
[v-cloak] {
  display: none; }

@font-face {
  font-family: "CirceLight";
  src: url("/fonts/Circe-Light.ttf"); }

@font-face {
  font-family: "CirceRegular";
  src: url("/fonts/Circe-Regular.ttf"); }

@font-face {
  font-family: "CirceBold";
  src: url("/fonts/Circe-Bold.ttf"); }

.bg-primary {
  background: #3e8690; }

.text-primary {
  color: #3e8690; }

.text-secondary {
  color: #59c0cf; }

.text-right {
  text-align: right; }

.text-left {
  text-align: left; }

.text-center {
  text-align: center; }

.pull-right {
  float: right; }

.pull-left {
  float: left; }

.clearfix {
  clear: both; }

.hidden {
  display: none; }

.inline-block {
  display: inline-block; }

.text-muted {
  text-decoration: line-through;
  color: #646464; }

.rotate180 {
  transform: rotate3d(0, 0, 1, 180deg); }

.v-align {
  position: relative;
  top: 50%;
  transform: translateY(50%); }

.pd-sm {
  padding: 1rem; }

.pd {
  padding: 2rem; }

.pd-lg {
  padding: 4rem; }

.img-responsive {
  max-width: 100%; }

.inner {
  padding-left: 4rem;
  padding-right: 4rem; }
  @media screen and (max-width: 768px) {
    .inner {
      padding-left: 2rem;
      padding-right: 2rem; } }
  @media screen and (max-width: 642px) {
    .inner {
      padding-left: 1rem;
      padding-right: 1rem; } }

html {
  box-sizing: border-box;
  font-weight: 200; }

*, *:before, *:after {
  box-sizing: inherit; }

html {
  font-size: 16px, "Helvetica", "Arial", "sans-serif";
  font-family: "Aktiv Grotesk W01", "Helvetica", "Arial", "sans-serif";
  font-weight: 200;
  line-height: 1.2;
  box-sizing: border-box; }
  @media screen and (max-width: 1200px) {
    html {
      font-size: 14px; } }
  @media screen and (max-width: 768px) {
    html {
      font-size: 13px; } }
  @media screen and (max-width: 642px) {
    html {
      font-size: 12px; } }
  html h1, html h2, html h3, html h4, html h5, html h6 {
    font-weight: 200; }

body {
  background-color: #ececec; }

html * {
  box-sizing: border-box; }

a {
  color: inherit;
  text-decoration: none; }

/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

a {
  color: unset;
  text-decoration: none; }

footer .inner {
  padding-top: 4rem;
  padding-bottom: 1.5rem;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -ms-flex-pack: justify;
  justify-content: space-between;
  background-color: #231f20;
  color: #ffffff; }
  @media screen and (max-width: 768px) {
    footer .inner {
      padding-top: 1.5rem; } }
  footer .inner .credits {
    text-transform: uppercase;
    font-weight: bold;
    -ms-flex: 1 1 35%;
    flex: 1 1 35%;
    margin-bottom: 2rem; }
    @media screen and (max-width: 768px) {
      footer .inner .credits {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%; } }
    footer .inner .credits h2 {
      font-size: 1.2rem;
      font-weight: bold; }
    footer .inner .credits p {
      font-size: 1.2rem;
      margin: 2em 0 1em;
      line-height: 1.5;
      max-width: 380px; }
    footer .inner .credits p:before,
    footer .inner .credits a:before {
      content: '—';
      display: block;
      margin-bottom: 0.25em; }
    footer .inner .credits a:hover {
      border-bottom: 1px solid #fff; }
  footer .inner .case-study {
    -ms-flex: 1 1 50%;
    flex: 1 1 50%; }
    footer .inner .case-study p {
      max-width: 540px;
      font-weight: 200;
      font-size: 1.8rem;
      line-height: 1.5; }
      footer .inner .case-study p:first-of-type {
        margin-bottom: 1.25em; }
    footer .inner .case-study a {
      text-decoration: none;
      border-bottom: 1px solid #fff;
      color: #fff; }
      footer .inner .case-study a:hover {
        border-bottom: 0; }
  footer .inner .logo {
    display: inline-block;
    -ms-flex: 1 1 5%;
    flex: 1 1 5%;
    margin-bottom: 2rem; }
    @media screen and (max-width: 768px) {
      footer .inner .logo {
        -ms-flex: 1 1 100%;
        flex: 1 1 100%;
        -ms-flex-order: -1;
        order: -1; } }

.top-nav .inner {
  padding-top: 1rem;
  padding-bottom: 1rem;
  background-color: #fff;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -ms-flex-pack: justify;
  justify-content: space-between; }
  @media screen and (max-width: 642px) {
    .top-nav .inner {
      padding-top: 1rem;
      padding-bottom: 1rem; } }
  .top-nav .inner .mobile-menu {
    display: none;
    width: 100%;
    padding-bottom: 2rem; }
    .top-nav .inner .mobile-menu ul li {
      font-size: 2rem;
      color: #3e8690;
      border-bottom: 2px dashed #646464;
      text-align: center;
      padding: 1rem 0; }
    @media screen and (max-width: 642px) {
      .top-nav .inner .mobile-menu {
        margin-top: -1rem; } }
  .top-nav .inner .left img {
    padding-right: 2rem; }
  .top-nav .inner .right {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center; }
    .top-nav .inner .right li {
      display: inline-block;
      padding-left: 4rem;
      color: #3e8690;
      text-transform: uppercase;
      font-weight: normal; }
      @media screen and (max-width: 1200px) {
        .top-nav .inner .right li {
          padding-left: 0.5rem; } }
      .top-nav .inner .right li i {
        font-size: 1.2rem;
        color: #3e8690;
        padding-left: 1rem;
        vertical-align: middle; }
    @media screen and (max-width: 768px) {
      .top-nav .inner .right.desktop {
        display: none; } }
    .top-nav .inner .right.mobile {
      display: none; }
      @media screen and (max-width: 768px) {
        .top-nav .inner .right.mobile {
          display: -ms-flexbox;
          display: flex; } }
  .top-nav .inner .has-menu-dropdown {
    position: relative; }
  .top-nav .inner .menu-dropdown-link {
    cursor: pointer; }
  .top-nav .inner .menu-dropdown {
    display: none;
    z-index: 99999;
    padding: 1rem 3rem 1rem 1rem;
    position: absolute;
    box-shadow: #59c0cf 0px 5px 0px 0px inset;
    border-bottom: 5px solid #59c0cf;
    background-color: #3e8690;
    color: #ffffff;
    text-align: left;
    top: 3rem; }
    .top-nav .inner .menu-dropdown.active {
      display: block; }
    .top-nav .inner .menu-dropdown .menu-dropdown-item {
      padding-top: 0.5rem;
      padding-bottom: 1rem; }
      .top-nav .inner .menu-dropdown .menu-dropdown-item.item--level1 .menu-icon {
        font-size: 3rem; }
      .top-nav .inner .menu-dropdown .menu-dropdown-item.item--level2 > a {
        margin-left: 3rem; }
      .top-nav .inner .menu-dropdown .menu-dropdown-item.item--level2 .menu-icon {
        font-size: 2rem; }
    .top-nav .inner .menu-dropdown .menu-icon {
      color: #ffffff;
      padding-left: 0;
      width: 25px; }
    .top-nav .inner .menu-dropdown a {
      white-space: nowrap; }
    .top-nav .inner .menu-dropdown::before {
      position: absolute;
      top: 0;
      content: '';
      width: 0;
      height: 0;
      border-left: 30px solid transparent;
      border-right: 30px solid transparent;
      border-bottom: 30px solid #3e8690;
      transform: translateY(-15px); }

.btn {
  text-transform: uppercase;
  border: 2px solid #59c0cf;
  color: #59c0cf;
  padding: 1rem 1rem;
  text-align: center;
  background: transparent;
  cursor: pointer;
  font-size: 1rem;
  line-height: 1rem;
  font-weight: 400;
  outline: none;
  overflow: hidden;
  position: relative;
  z-index: 1;
  min-width: 145px;
  /* This is for fix on flickering on desktop safari */
  display: inline-block; }
  .btn::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 150%;
    height: 100%;
    background-color: #59c0cf;
    z-index: -1;
    transform: rotate3d(0, 0, 1, -45deg) translate3d(0, -3em, 0);
    transform-origin: 0% 100%;
    transition: all .3s ease-in-out; }
  .btn:hover, .btn:focus {
    transition: all .3s ease-in-out;
    color: #2b5A61; }
    .btn:hover::before, .btn:focus::before {
      background-color: #59c0cf;
      transform: rotate3d(0, 0, 1, 0deg); }
  .btn.btn-with-bg {
    background-color: #59c0cf;
    color: #2b5A61; }
    .btn.btn-with-bg::before {
      background-color: #0d3855; }
    .btn.btn-with-bg:hover, .btn.btn-with-bg:focus {
      color: #59c0cf; }
      .btn.btn-with-bg:hover::before, .btn.btn-with-bg:focus::before {
        transform: rotate3d(0, 0, 1, 0deg); }

.btn-gap {
  display: inline-block;
  width: 1rem; }
  @media screen and (max-width: 642px) {
    .btn-gap {
      display: none; } }

.btn-1 {
  border: 0px;
  color: #D7191E;
  margin: 0.5rem;
  padding: 1rem 0.5rem;
  text-align: center;
  background: transparent;
  cursor: pointer;
  font-size: 1.5rem;
  font-weight: normal;
  outline: none;
  overflow: hidden;
  position: relative;
  display: inline-block; }
  .btn-1::before, .btn-1::after {
    content: '';
    position: absolute;
    transform: scaleX(0);
    right: 0;
    left: 0;
    height: 3px;
    background-color: #3e8690;
    opacity: 0; }
  .btn-1::before {
    top: 0;
    transform-origin: 0 0; }
  .btn-1::after {
    bottom: 0;
    transform-origin: 100% 100%; }
  .btn-1:hover, .btn-1:focus {
    transition: all 0.3s ease-in-out;
    color: #3e8690; }
    .btn-1:hover::before, .btn-1:hover::after, .btn-1:focus::before, .btn-1:focus::after {
      transition: all 0.3s ease-in-out;
      transform: scaleX(1);
      opacity: 1; }

.btn-2 {
  border: 0;
  outline: 0;
  font-size: 2rem;
  background-color: transparent;
  cursor: pointer;
  position: relative;
  text-align: center;
  width: 4rem;
  height: 4rem;
  display: inline-block; }
  .btn-2 [class^=icon-] {
    position: relative; }
  .btn-2::before {
    content: '';
    background-color: transparent;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    border-radius: 9999px; }
  .btn-2::after {
    content: '';
    border: 2px solid transparent;
    background-color: transparent;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    border-radius: 9999px; }
  .btn-2:focus::before, .btn-2:hover::before {
    animation: btn-2-before-animation 2s infinite; }
  .btn-2:focus::after, .btn-2:hover::after {
    animation: btn-2-after-animation 2s infinite; }

.btn-3 {
  border: 0;
  background: transparent;
  color: inherit;
  font-size: inherit;
  font-weight: inherit;
  cursor: pointer;
  padding: 0;
  margin: 0; }

.btn-share-link {
  border: 2px solid #59c0cf;
  background: transparent;
  color: #59c0cf;
  font-size: inherit;
  font-weight: inherit;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  border-radius: 5px;
  cursor: pointer;
  margin: 0; }

.click-feedback-1 {
  position: relative; }

.click-feedback-1::after {
  content: ' ';
  position: absolute;
  right: 6px;
  left: 6px;
  top: 6px;
  bottom: 6px; }

.click-feedback-1-click::after {
  border: 3px solid rgba(255, 255, 255, 0.1);
  border-radius: 0 10px 0 0;
  animation: subtle-feedback-1 0.5s forwards; }

@keyframes subtle-feedback-1 {
  0% {
    transform: scale3d(0.3, 0.3, 1); }
  25%, 50% {
    opacity: 1; }
  100% {
    opacity: 0;
    transform: scale3d(1.3, 1.3, 1); } }

.form {
  font-weight: normal; }
  .form .form-field {
    border-top: 2px solid #59c0cf;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    padding-bottom: 6rem; }
    .form .form-field .field-heading {
      -ms-flex: 0 0 100%;
      flex: 0 0 100%;
      color: #59c0cf;
      text-transform: uppercase;
      padding-top: 0.5rem;
      padding-bottom: 3rem; }
    .form .form-field .field-left, .form .form-field .field-right {
      -ms-flex: 0 0 45%;
      flex: 0 0 45%; }
      @media screen and (max-width: 768px) {
        .form .form-field .field-left, .form .form-field .field-right {
          -ms-flex: 0 0 100%;
          flex: 0 0 100%; } }
  .form .form-group--moved-up {
    position: relative;
    top: -24rem; }
    @media screen and (max-width: 1200px) {
      .form .form-group--moved-up {
        top: 0; } }
  .form .form-group-name-row {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: justify;
    justify-content: space-between; }
    .form .form-group-name-row .form-group {
      -ms-flex: 0 0 48%;
      flex: 0 0 48%; }
  .form .form-group {
    margin-top: 2.5rem;
    position: relative; }
    .form .form-group.form-group-gap-sm {
      margin-top: 1rem; }
    .form .form-group .form-group__info {
      line-height: 1.5;
      color: #59c0cf;
      padding-top: 0.5rem; }
    .form .form-group label.control-label {
      display: block;
      color: #59c0cf; }
    .form .form-group input[type=text].form-control, .form .form-group input[type=email].form-control, .form .form-group input[type=password].form-control {
      border: 2px solid #ffffff;
      background: transparent;
      padding: 1rem;
      font-size: 1rem;
      color: #ffffff;
      outline: none;
      line-height: 1; }
      .form .form-group input[type=text].form-control:focus, .form .form-group input[type=email].form-control:focus, .form .form-group input[type=password].form-control:focus {
        transition: all 0.3s ease-in-out;
        border-color: #59c0cf; }
      .form .form-group input[type=text].form-control + label.control-label, .form .form-group input[type=email].form-control + label.control-label, .form .form-group input[type=password].form-control + label.control-label {
        pointer-events: none;
        position: absolute;
        top: 37%;
        left: 1rem;
        right: 1rem;
        transition: transform 0.5s, color 0.5s;
        transform-origin: 0 0;
        text-transform: uppercase; }
      .form .form-group input[type=text].form-control.input-filled + label.control-label, .form .form-group input[type=email].form-control.input-filled + label.control-label, .form .form-group input[type=password].form-control.input-filled + label.control-label {
        transform: translate3d(-1rem, -2.5rem, 0) scale3d(0.9, 0.9, 1); }
      .form .form-group input[type=text].form-control.has-pen:focus + label.control-label::after, .form .form-group input[type=email].form-control.has-pen:focus + label.control-label::after, .form .form-group input[type=password].form-control.has-pen:focus + label.control-label::after {
        opacity: 0;
        display: inline-block;
        position: absolute;
        right: 0;
        content: "\e900";
        color: #59c0cf;
        font-family: 'icomoon' !important;
        transform-origin: 0 100%;
        animation: pen-animation 4s infinite; }
    .form .form-group .input-addon {
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      padding: 1.1rem;
      color: #59c0cf;
      border-left: 2px solid #ffffff; }
    .form .form-group.form-group-with-addon {
      display: -ms-flexbox;
      display: flex; }
      .form .form-group.form-group-with-addon input.form-control {
        border-right: transparent; }
      .form .form-group.form-group-with-addon span.input-addon {
        border: 2px solid #ffffff;
        padding: 1rem; }
    .form .form-group select.form-control {
      border: 2px solid #ffffff;
      font-size: 1rem;
      height: 54px;
      background: transparent;
      color: #59c0cf; }
    .form .form-group .radio {
      margin-top: 1.5rem;
      margin-bottom: 0.5rem;
      display: inline-block; }
      .form .form-group .radio label span {
        position: relative;
        cursor: pointer;
        padding-left: 3rem;
        padding-right: 4rem;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem; }
      .form .form-group .radio label span::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        height: 2rem;
        width: 2rem;
        border: 2px solid #ffffff;
        border-radius: 9999px;
        transition: transform 0.2s, border-color 0.2s; }
      .form .form-group .radio label span::after {
        content: '';
        position: absolute;
        top: 0.25rem;
        left: 0.25rem;
        height: 1.5rem;
        width: 1.5rem;
        border-radius: 9999px;
        background-color: #ffffff;
        transition: transform 0.3s, background-color 0.3s, opacity 0.3s;
        opacity: 0;
        transform: scale3d(0.25, 0.25, 1); }
      .form .form-group .radio input[type='radio'] {
        position: absolute;
        overflow: hidden;
        clip: rect(0 0 0 0);
        height: 1px;
        width: 1px;
        margin: -1px;
        padding: 0;
        border: 0; }
        .form .form-group .radio input[type='radio']:checked + span::after {
          opacity: 1;
          transform: scale3d(1, 1, 1); }
        .form .form-group .radio input[type='radio']:focus + span::before {
          border-color: #59c0cf;
          transform: scale3d(1.1, 1.1, 1); }
    .form .form-group.stretch-radio {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-flow: row wrap;
      flex-flow: row wrap;
      -ms-flex-pack: justify;
      justify-content: space-between; }
      .form .form-group.stretch-radio > label {
        width: 100%; }
    .form .form-group .checkbox {
      margin-top: 1.5rem;
      margin-bottom: 0.5rem;
      display: inline-block; }
      .form .form-group .checkbox label span {
        position: relative;
        cursor: pointer;
        padding-left: 3rem;
        padding-right: 1rem;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem; }
      .form .form-group .checkbox label span::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        height: 2rem;
        width: 2rem;
        border: 2px solid #ffffff;
        transition: transform 0.2s, border-color 0.2s;
        line-height: 1; }
      .form .form-group .checkbox label span::after {
        line-height: 1;
        content: '\74';
        font-family: 'icomoon';
        font-size: 2rem;
        position: absolute;
        top: 0rem;
        left: 0.1rem;
        height: 1.5rem;
        width: 1.5rem;
        transition: transform 0.3s, color 0.3s, opacity 0.3s;
        opacity: 0;
        transform: scale3d(0.25, 0.25, 1); }
      .form .form-group .checkbox input[type='checkbox'] {
        position: absolute;
        overflow: hidden;
        clip: rect(0 0 0 0);
        height: 1px;
        width: 1px;
        margin: -1px;
        padding: 0;
        border: 0; }
        .form .form-group .checkbox input[type='checkbox']:checked + span::after {
          color: #59c0cf;
          opacity: 1;
          transform: scale3d(1, 1, 1); }
        .form .form-group .checkbox input[type='checkbox']:focus + span::before {
          border-color: #59c0cf;
          transform: scale3d(1.1, 1.1, 1); }
  .form.form--theme-light-bg {
    color: #2b5A61; }
    .form.form--theme-light-bg label.control-label {
      color: #2b5A61; }
    .form.form--theme-light-bg input[type=text].form-control, .form.form--theme-light-bg input[type=email].form-control, .form.form--theme-light-bg input[type=password].form-control {
      color: #2b5A61;
      border-color: #2b5A61; }
      .form.form--theme-light-bg input[type=text].form-control.input-filled + label.control-label, .form.form--theme-light-bg input[type=email].form-control.input-filled + label.control-label, .form.form--theme-light-bg input[type=password].form-control.input-filled + label.control-label {
        color: #2b5A61; }
    .form.form--theme-light-bg .input-addon {
      color: #2b5A61;
      border-left: 2px solid #2b5A61; }
    .form.form--theme-light-bg .form-group {
      margin-top: 1.5rem; }
      .form.form--theme-light-bg .form-group .radio label span::before {
        border-color: #2b5A61; }
      .form.form--theme-light-bg .form-group .radio input[type='radio']:checked + span::after {
        background-color: #2b5A61; }
      .form.form--theme-light-bg .form-group .radio input[type='radio']:focus + span::before {
        border-color: #2b5A61; }
    .form.form--theme-light-bg .jz-select-wrapper > span.is-placeholder {
      color: #2b5A61; }
    .form.form--theme-light-bg .jz-select-wrapper .jz-placeholder {
      border: 2px solid #2b5A61; }
    .form.form--theme-light-bg .jz-select-wrapper .jz-options {
      border-width: 0 2px 2px 2px;
      border-color: #2b5A61;
      border-style: solid; }
    .form.form--theme-light-bg .btn {
      border-color: #2b5A61;
      color: #2b5A61; }
      .form.form--theme-light-bg .btn:hover, .form.form--theme-light-bg .btn:focus {
        color: #59c0cf; }
      .form.form--theme-light-bg .btn::before {
        background-color: #2b5A61; }
    .form.form--theme-light-bg .race-type-select-wrapper .col .icon-container {
      border: 4px solid #2b5A61; }
    .form.form--theme-light-bg .race-type-select-wrapper .col.col--active .icon-container {
      border-color: #cad401;
      box-shadow: 0 0 0 2px #cad401; }
    .form.form--theme-light-bg .race-type-select-wrapper .button-radio input[type=radio] + span {
      border: 2px solid #2b5A61;
      color: #2b5A61; }
    .form.form--theme-light-bg .race-type-select-wrapper .button-radio input[type=radio]:checked + span {
      border: 2px solid #59c0cf;
      box-shadow: 0px 0px 0px 2px #59c0cf; }

.form-admin {
  width: 100%; }
  .form-admin .form-group {
    padding-top: 1rem; }
    .form-admin .form-group label.control-label {
      display: block;
      padding-bottom: 1rem;
      font-weight: normal;
      font-size: 1rem; }
    .form-admin .form-group input[type=text].form-control, .form-admin .form-group input[type=email].form-control, .form-admin .form-group input[type=password].form-control {
      border: 2px solid #646464;
      border-radius: 5px;
      background: transparent;
      padding: 1rem;
      font-size: 1rem;
      color: #3e8690;
      outline: none; }
      .form-admin .form-group input[type=text].form-control:focus, .form-admin .form-group input[type=email].form-control:focus, .form-admin .form-group input[type=password].form-control:focus {
        transition: all 0.3s ease-in-out;
        border-color: #3e8690; }
    .form-admin .form-group select.form-control {
      border: 2px solid #000000;
      border-radius: 5px;
      font-size: 1rem;
      height: 54px;
      background: transparent;
      color: #3e8690;
      width: 100%; }
    .form-admin .form-group .datepickbox input + .control-label {
      position: absolute;
      top: 1rem;
      right: 0; }

.form-inline {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -ms-flex-pack: justify;
  justify-content: space-between; }

.form-inline-around {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -ms-flex-pack: distribute;
  justify-content: space-around; }

.add-admin-input {
  color: #ffffff;
  padding: 0.5rem;
  border: 2px solid #ffffff;
  background-color: transparent;
  border-radius: 5px; }

.table {
  width: 100%; }
  .table th, .table td {
    text-align: left;
    padding: 0.5rem; }
  .table thead th {
    border-bottom: 2px solid #646464; }

.price-table {
  width: 100%; }
  .price-table td {
    padding: 1rem; }
  .price-table tbody td {
    color: #2b5A61;
    background-color: #ffffff; }
    .price-table tbody td:last-of-type {
      width: 40%;
      text-align: right; }
  .price-table tbody .discount td {
    color: #D7191E; }
  .price-table tfoot {
    border-top: 1px solid #2b5A61; }
  .price-table .table-footer-row td {
    color: #2b5A61;
    background-color: #59c0cf; }
    .price-table .table-footer-row td:last-of-type {
      width: 40%;
      text-align: right; }

.has-drop-tooltip {
  cursor: pointer; }

.medal-insert-help, .vip-help {
  cursor: pointer; }

.no-race-number-help {
  cursor: pointer; }

.expo-info {
  line-height: 1.5; }
  .expo-info .expo-schedule {
    line-height: 1;
    padding-top: 1rem; }
    .expo-info .expo-schedule li {
      padding: 0.5rem 0 0 4rem; }

/*!
 * Hamburgers
 * @description Tasty CSS-animated hamburgers
 * @author Jonathan Suh @jonsuh
 * @site https://jonsuh.com/hamburgers
 * @link https://github.com/jonsuh/hamburgers
 */
.hamburger {
  padding: 15px 15px;
  display: inline-block;
  cursor: pointer;
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible; }
  .hamburger:hover {
    opacity: 0.7; }

.hamburger-box {
  width: 40px;
  height: 24px;
  display: inline-block;
  position: relative; }

.hamburger-inner {
  display: block;
  top: 50%;
  margin-top: -2px; }
  .hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
    width: 40px;
    height: 4px;
    background-color: #59c0cf;
    border-radius: 4px;
    position: absolute;
    transition-property: transform;
    transition-duration: 0.15s;
    transition-timing-function: ease; }
  .hamburger-inner::before, .hamburger-inner::after {
    content: "";
    display: block; }
  .hamburger-inner::before {
    top: -10px; }
  .hamburger-inner::after {
    bottom: -10px; }

/*
   * 3DX
   */
.hamburger--3dx .hamburger-box {
  perspective: 80px; }

.hamburger--3dx .hamburger-inner {
  transition: transform 0.2s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1); }
  .hamburger--3dx .hamburger-inner::before, .hamburger--3dx .hamburger-inner::after {
    transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1); }

.hamburger--3dx.is-active .hamburger-inner {
  background-color: transparent;
  transform: rotateY(180deg); }
  .hamburger--3dx.is-active .hamburger-inner::before {
    transform: translate3d(0, 10px, 0) rotate(45deg); }
  .hamburger--3dx.is-active .hamburger-inner::after {
    transform: translate3d(0, -10px, 0) rotate(-45deg); }

/*
   * 3DX Reverse
   */
.hamburger--3dx-r .hamburger-box {
  perspective: 80px; }

.hamburger--3dx-r .hamburger-inner {
  transition: transform 0.2s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1); }
  .hamburger--3dx-r .hamburger-inner::before, .hamburger--3dx-r .hamburger-inner::after {
    transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1); }

.hamburger--3dx-r.is-active .hamburger-inner {
  background-color: transparent;
  transform: rotateY(-180deg); }
  .hamburger--3dx-r.is-active .hamburger-inner::before {
    transform: translate3d(0, 10px, 0) rotate(45deg); }
  .hamburger--3dx-r.is-active .hamburger-inner::after {
    transform: translate3d(0, -10px, 0) rotate(-45deg); }

/*
   * 3DY
   */
.hamburger--3dy .hamburger-box {
  perspective: 80px; }

.hamburger--3dy .hamburger-inner {
  transition: transform 0.2s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1); }
  .hamburger--3dy .hamburger-inner::before, .hamburger--3dy .hamburger-inner::after {
    transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1); }

.hamburger--3dy.is-active .hamburger-inner {
  background-color: transparent;
  transform: rotateX(-180deg); }
  .hamburger--3dy.is-active .hamburger-inner::before {
    transform: translate3d(0, 10px, 0) rotate(45deg); }
  .hamburger--3dy.is-active .hamburger-inner::after {
    transform: translate3d(0, -10px, 0) rotate(-45deg); }

/*
   * 3DY Reverse
   */
.hamburger--3dy-r .hamburger-box {
  perspective: 80px; }

.hamburger--3dy-r .hamburger-inner {
  transition: transform 0.2s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1); }
  .hamburger--3dy-r .hamburger-inner::before, .hamburger--3dy-r .hamburger-inner::after {
    transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1); }

.hamburger--3dy-r.is-active .hamburger-inner {
  background-color: transparent;
  transform: rotateX(180deg); }
  .hamburger--3dy-r.is-active .hamburger-inner::before {
    transform: translate3d(0, 10px, 0) rotate(45deg); }
  .hamburger--3dy-r.is-active .hamburger-inner::after {
    transform: translate3d(0, -10px, 0) rotate(-45deg); }

/*
   * Arrow
   */
.hamburger--arrow.is-active .hamburger-inner::before {
  transform: translate3d(-8px, 0, 0) rotate(-45deg) scale(0.7, 1); }

.hamburger--arrow.is-active .hamburger-inner::after {
  transform: translate3d(-8px, 0, 0) rotate(45deg) scale(0.7, 1); }

/*
   * Arrow Right
   */
.hamburger--arrow-r.is-active .hamburger-inner::before {
  transform: translate3d(8px, 0, 0) rotate(45deg) scale(0.7, 1); }

.hamburger--arrow-r.is-active .hamburger-inner::after {
  transform: translate3d(8px, 0, 0) rotate(-45deg) scale(0.7, 1); }

/*
   * Arrow Alt
   */
.hamburger--arrowalt .hamburger-inner::before {
  transition: top 0.1s 0.15s ease, transform 0.15s cubic-bezier(0.165, 0.84, 0.44, 1); }

.hamburger--arrowalt .hamburger-inner::after {
  transition: bottom 0.1s 0.15s ease, transform 0.15s cubic-bezier(0.165, 0.84, 0.44, 1); }

.hamburger--arrowalt.is-active .hamburger-inner::before {
  top: 0;
  transform: translate3d(-8px, -10px, 0) rotate(-45deg) scale(0.7, 1);
  transition: top 0.1s ease, transform 0.15s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22); }

.hamburger--arrowalt.is-active .hamburger-inner::after {
  bottom: 0;
  transform: translate3d(-8px, 10px, 0) rotate(45deg) scale(0.7, 1);
  transition: bottom 0.1s ease, transform 0.15s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22); }

/*
   * Arrow Alt Right
   */
.hamburger--arrowalt-r .hamburger-inner::before {
  transition: top 0.1s 0.15s ease, transform 0.15s cubic-bezier(0.165, 0.84, 0.44, 1); }

.hamburger--arrowalt-r .hamburger-inner::after {
  transition: bottom 0.1s 0.15s ease, transform 0.15s cubic-bezier(0.165, 0.84, 0.44, 1); }

.hamburger--arrowalt-r.is-active .hamburger-inner::before {
  top: 0;
  transform: translate3d(8px, -10px, 0) rotate(45deg) scale(0.7, 1);
  transition: top 0.1s ease, transform 0.15s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22); }

.hamburger--arrowalt-r.is-active .hamburger-inner::after {
  bottom: 0;
  transform: translate3d(8px, 10px, 0) rotate(-45deg) scale(0.7, 1);
  transition: bottom 0.1s ease, transform 0.15s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22); }

/*
   * Boring
   */
.hamburger--boring .hamburger-inner, .hamburger--boring .hamburger-inner::before, .hamburger--boring .hamburger-inner::after {
  transition-property: none; }

.hamburger--boring.is-active .hamburger-inner {
  transform: rotate(45deg); }
  .hamburger--boring.is-active .hamburger-inner::before {
    top: 0;
    opacity: 0; }
  .hamburger--boring.is-active .hamburger-inner::after {
    bottom: 0;
    transform: rotate(-90deg); }

/*
   * Collapse
   */
.hamburger--collapse .hamburger-inner {
  top: auto;
  bottom: 0;
  transition-duration: 0.15s;
  transition-delay: 0.15s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  .hamburger--collapse .hamburger-inner::after {
    top: -20px;
    transition: top 0.3s 0.3s cubic-bezier(0.33333, 0.66667, 0.66667, 1), opacity 0.1s linear; }
  .hamburger--collapse .hamburger-inner::before {
    transition: top 0.12s 0.3s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--collapse.is-active .hamburger-inner {
  transform: translate3d(0, -10px, 0) rotate(-45deg);
  transition-delay: 0.32s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  .hamburger--collapse.is-active .hamburger-inner::after {
    top: 0;
    opacity: 0;
    transition: top 0.3s cubic-bezier(0.33333, 0, 0.66667, 0.33333), opacity 0.1s 0.27s linear; }
  .hamburger--collapse.is-active .hamburger-inner::before {
    top: 0;
    transform: rotate(-90deg);
    transition: top 0.12s 0.18s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.15s 0.42s cubic-bezier(0.215, 0.61, 0.355, 1); }

/*
   * Collapse Reverse
   */
.hamburger--collapse-r .hamburger-inner {
  top: auto;
  bottom: 0;
  transition-duration: 0.15s;
  transition-delay: 0.15s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  .hamburger--collapse-r .hamburger-inner::after {
    top: -20px;
    transition: top 0.3s 0.3s cubic-bezier(0.33333, 0.66667, 0.66667, 1), opacity 0.1s linear; }
  .hamburger--collapse-r .hamburger-inner::before {
    transition: top 0.12s 0.3s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--collapse-r.is-active .hamburger-inner {
  transform: translate3d(0, -10px, 0) rotate(45deg);
  transition-delay: 0.32s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  .hamburger--collapse-r.is-active .hamburger-inner::after {
    top: 0;
    opacity: 0;
    transition: top 0.3s cubic-bezier(0.33333, 0, 0.66667, 0.33333), opacity 0.1s 0.27s linear; }
  .hamburger--collapse-r.is-active .hamburger-inner::before {
    top: 0;
    transform: rotate(90deg);
    transition: top 0.12s 0.18s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.15s 0.42s cubic-bezier(0.215, 0.61, 0.355, 1); }

/*
   * Elastic
   */
.hamburger--elastic .hamburger-inner {
  top: 2px;
  transition-duration: 0.4s;
  transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55); }
  .hamburger--elastic .hamburger-inner::before {
    top: 10px;
    transition: opacity 0.15s 0.4s ease; }
  .hamburger--elastic .hamburger-inner::after {
    top: 20px;
    transition: transform 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55); }

.hamburger--elastic.is-active .hamburger-inner {
  transform: translate3d(0, 10px, 0) rotate(135deg);
  transition-delay: 0.1s; }
  .hamburger--elastic.is-active .hamburger-inner::before {
    transition-delay: 0s;
    opacity: 0; }
  .hamburger--elastic.is-active .hamburger-inner::after {
    transform: translate3d(0, -20px, 0) rotate(-270deg);
    transition-delay: 0.1s; }

/*
   * Elastic Reverse
   */
.hamburger--elastic-r .hamburger-inner {
  top: 2px;
  transition-duration: 0.4s;
  transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55); }
  .hamburger--elastic-r .hamburger-inner::before {
    top: 10px;
    transition: opacity 0.15s 0.4s ease; }
  .hamburger--elastic-r .hamburger-inner::after {
    top: 20px;
    transition: transform 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55); }

.hamburger--elastic-r.is-active .hamburger-inner {
  transform: translate3d(0, 10px, 0) rotate(-135deg);
  transition-delay: 0.1s; }
  .hamburger--elastic-r.is-active .hamburger-inner::before {
    transition-delay: 0s;
    opacity: 0; }
  .hamburger--elastic-r.is-active .hamburger-inner::after {
    transform: translate3d(0, -20px, 0) rotate(270deg);
    transition-delay: 0.1s; }

/*
   * Emphatic
   */
.hamburger--emphatic {
  overflow: hidden; }
  .hamburger--emphatic .hamburger-inner {
    transition: background-color 0.2s 0.25s ease-in; }
    .hamburger--emphatic .hamburger-inner::before {
      left: 0;
      transition: transform 0.2s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s 0.2s linear, left 0.2s 0.25s ease-in; }
    .hamburger--emphatic .hamburger-inner::after {
      top: 10px;
      right: 0;
      transition: transform 0.2s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s 0.2s linear, right 0.2s 0.25s ease-in; }
  .hamburger--emphatic.is-active .hamburger-inner {
    transition-delay: 0s;
    transition-timing-function: ease-out;
    background-color: transparent; }
    .hamburger--emphatic.is-active .hamburger-inner::before {
      left: -80px;
      top: -80px;
      transform: translate3d(80px, 80px, 0) rotate(45deg);
      transition: left 0.2s ease-out, top 0.05s 0.2s linear, transform 0.2s 0.25s cubic-bezier(0.075, 0.82, 0.165, 1); }
    .hamburger--emphatic.is-active .hamburger-inner::after {
      right: -80px;
      top: -80px;
      transform: translate3d(-80px, 80px, 0) rotate(-45deg);
      transition: right 0.2s ease-out, top 0.05s 0.2s linear, transform 0.2s 0.25s cubic-bezier(0.075, 0.82, 0.165, 1); }

/*
   * Emphatic Reverse
   */
.hamburger--emphatic-r {
  overflow: hidden; }
  .hamburger--emphatic-r .hamburger-inner {
    transition: background-color 0.2s 0.25s ease-in; }
    .hamburger--emphatic-r .hamburger-inner::before {
      left: 0;
      transition: transform 0.2s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s 0.2s linear, left 0.2s 0.25s ease-in; }
    .hamburger--emphatic-r .hamburger-inner::after {
      top: 10px;
      right: 0;
      transition: transform 0.2s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s 0.2s linear, right 0.2s 0.25s ease-in; }
  .hamburger--emphatic-r.is-active .hamburger-inner {
    transition-delay: 0s;
    transition-timing-function: ease-out;
    background-color: transparent; }
    .hamburger--emphatic-r.is-active .hamburger-inner::before {
      left: -80px;
      top: 80px;
      transform: translate3d(80px, -80px, 0) rotate(-45deg);
      transition: left 0.2s ease-out, top 0.05s 0.2s linear, transform 0.2s 0.25s cubic-bezier(0.075, 0.82, 0.165, 1); }
    .hamburger--emphatic-r.is-active .hamburger-inner::after {
      right: -80px;
      top: 80px;
      transform: translate3d(-80px, -80px, 0) rotate(45deg);
      transition: right 0.2s ease-out, top 0.05s 0.2s linear, transform 0.2s 0.25s cubic-bezier(0.075, 0.82, 0.165, 1); }

/*
   * Slider
   */
.hamburger--slider .hamburger-inner {
  top: 2px; }
  .hamburger--slider .hamburger-inner::before {
    top: 10px;
    transition-property: transform, opacity;
    transition-timing-function: ease;
    transition-duration: 0.2s; }
  .hamburger--slider .hamburger-inner::after {
    top: 20px; }

.hamburger--slider.is-active .hamburger-inner {
  transform: translate3d(0, 10px, 0) rotate(45deg); }
  .hamburger--slider.is-active .hamburger-inner::before {
    transform: rotate(-45deg) translate3d(-5.7142857143px, -6px, 0);
    opacity: 0; }
  .hamburger--slider.is-active .hamburger-inner::after {
    transform: translate3d(0, -20px, 0) rotate(-90deg); }

/*
   * Slider Reverse
   */
.hamburger--slider-r .hamburger-inner {
  top: 2px; }
  .hamburger--slider-r .hamburger-inner::before {
    top: 10px;
    transition-property: transform, opacity;
    transition-timing-function: ease;
    transition-duration: 0.2s; }
  .hamburger--slider-r .hamburger-inner::after {
    top: 20px; }

.hamburger--slider-r.is-active .hamburger-inner {
  transform: translate3d(0, 10px, 0) rotate(-45deg); }
  .hamburger--slider-r.is-active .hamburger-inner::before {
    transform: rotate(45deg) translate3d(5.7142857143px, -6px, 0);
    opacity: 0; }
  .hamburger--slider-r.is-active .hamburger-inner::after {
    transform: translate3d(0, -20px, 0) rotate(90deg); }

/*
   * Spring
   */
.hamburger--spring .hamburger-inner {
  top: 2px;
  transition: background-color 0s 0.15s linear; }
  .hamburger--spring .hamburger-inner::before {
    top: 10px;
    transition: top 0.12s 0.3s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  .hamburger--spring .hamburger-inner::after {
    top: 20px;
    transition: top 0.3s 0.3s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--spring.is-active .hamburger-inner {
  transition-delay: 0.32s;
  background-color: transparent; }
  .hamburger--spring.is-active .hamburger-inner::before {
    top: 0;
    transition: top 0.12s 0.18s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.15s 0.32s cubic-bezier(0.215, 0.61, 0.355, 1);
    transform: translate3d(0, 10px, 0) rotate(45deg); }
  .hamburger--spring.is-active .hamburger-inner::after {
    top: 0;
    transition: top 0.3s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.15s 0.32s cubic-bezier(0.215, 0.61, 0.355, 1);
    transform: translate3d(0, 10px, 0) rotate(-45deg); }

/*
   * Spring Reverse
   */
.hamburger--spring-r .hamburger-inner {
  top: auto;
  bottom: 0;
  transition-duration: 0.15s;
  transition-delay: 0s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  .hamburger--spring-r .hamburger-inner::after {
    top: -20px;
    transition: top 0.3s 0.3s cubic-bezier(0.33333, 0.66667, 0.66667, 1), opacity 0s linear; }
  .hamburger--spring-r .hamburger-inner::before {
    transition: top 0.12s 0.3s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--spring-r.is-active .hamburger-inner {
  transform: translate3d(0, -10px, 0) rotate(-45deg);
  transition-delay: 0.32s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  .hamburger--spring-r.is-active .hamburger-inner::after {
    top: 0;
    opacity: 0;
    transition: top 0.3s cubic-bezier(0.33333, 0, 0.66667, 0.33333), opacity 0s 0.32s linear; }
  .hamburger--spring-r.is-active .hamburger-inner::before {
    top: 0;
    transform: rotate(90deg);
    transition: top 0.12s 0.18s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.15s 0.32s cubic-bezier(0.215, 0.61, 0.355, 1); }

/*
   * Stand
   */
.hamburger--stand .hamburger-inner {
  transition: transform 0.1s 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19), background-color 0s 0.1s linear; }
  .hamburger--stand .hamburger-inner::before {
    transition: top 0.1s 0.1s ease-in, transform 0.1s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  .hamburger--stand .hamburger-inner::after {
    transition: bottom 0.1s 0.1s ease-in, transform 0.1s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--stand.is-active .hamburger-inner {
  transform: rotate(90deg);
  background-color: transparent;
  transition: transform 0.1s 0s cubic-bezier(0.215, 0.61, 0.355, 1), background-color 0s 0.22s linear; }
  .hamburger--stand.is-active .hamburger-inner::before {
    top: 0;
    transform: rotate(-45deg);
    transition: top 0.1s 0.12s ease-out, transform 0.1s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1); }
  .hamburger--stand.is-active .hamburger-inner::after {
    bottom: 0;
    transform: rotate(45deg);
    transition: bottom 0.1s 0.12s ease-out, transform 0.1s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1); }

/*
   * Stand Reverse
   */
.hamburger--stand-r .hamburger-inner {
  transition: transform 0.1s 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19), background-color 0s 0.1s linear; }
  .hamburger--stand-r .hamburger-inner::before {
    transition: top 0.1s 0.1s ease-in, transform 0.1s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  .hamburger--stand-r .hamburger-inner::after {
    transition: bottom 0.1s 0.1s ease-in, transform 0.1s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--stand-r.is-active .hamburger-inner {
  transform: rotate(-90deg);
  background-color: transparent;
  transition: transform 0.1s 0s cubic-bezier(0.215, 0.61, 0.355, 1), background-color 0s 0.22s linear; }
  .hamburger--stand-r.is-active .hamburger-inner::before {
    top: 0;
    transform: rotate(-45deg);
    transition: top 0.1s 0.12s ease-out, transform 0.1s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1); }
  .hamburger--stand-r.is-active .hamburger-inner::after {
    bottom: 0;
    transform: rotate(45deg);
    transition: bottom 0.1s 0.12s ease-out, transform 0.1s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1); }

/*
   * Spin
   */
.hamburger--spin .hamburger-inner {
  transition-duration: 0.3s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  .hamburger--spin .hamburger-inner::before {
    transition: top 0.1s 0.34s ease-in, opacity 0.1s ease-in; }
  .hamburger--spin .hamburger-inner::after {
    transition: bottom 0.1s 0.34s ease-in, transform 0.3s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--spin.is-active .hamburger-inner {
  transform: rotate(225deg);
  transition-delay: 0.14s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  .hamburger--spin.is-active .hamburger-inner::before {
    top: 0;
    opacity: 0;
    transition: top 0.1s ease-out, opacity 0.1s 0.14s ease-out; }
  .hamburger--spin.is-active .hamburger-inner::after {
    bottom: 0;
    transform: rotate(-90deg);
    transition: bottom 0.1s ease-out, transform 0.3s 0.14s cubic-bezier(0.215, 0.61, 0.355, 1); }

/*
   * Spin Reverse
   */
.hamburger--spin-r .hamburger-inner {
  transition-duration: 0.3s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  .hamburger--spin-r .hamburger-inner::before {
    transition: top 0.1s 0.34s ease-in, opacity 0.1s ease-in; }
  .hamburger--spin-r .hamburger-inner::after {
    transition: bottom 0.1s 0.34s ease-in, transform 0.3s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--spin-r.is-active .hamburger-inner {
  transform: rotate(-225deg);
  transition-delay: 0.14s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  .hamburger--spin-r.is-active .hamburger-inner::before {
    top: 0;
    opacity: 0;
    transition: top 0.1s ease-out, opacity 0.1s 0.14s ease-out; }
  .hamburger--spin-r.is-active .hamburger-inner::after {
    bottom: 0;
    transform: rotate(-90deg);
    transition: bottom 0.1s ease-out, transform 0.3s 0.14s cubic-bezier(0.215, 0.61, 0.355, 1); }

/*
   * Squeeze
   */
.hamburger--squeeze .hamburger-inner {
  transition-duration: 0.1s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  .hamburger--squeeze .hamburger-inner::before {
    transition: top 0.1s 0.14s ease, opacity 0.1s ease; }
  .hamburger--squeeze .hamburger-inner::after {
    transition: bottom 0.1s 0.14s ease, transform 0.1s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--squeeze.is-active .hamburger-inner {
  transform: rotate(45deg);
  transition-delay: 0.14s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  .hamburger--squeeze.is-active .hamburger-inner::before {
    top: 0;
    opacity: 0;
    transition: top 0.1s ease, opacity 0.1s 0.14s ease; }
  .hamburger--squeeze.is-active .hamburger-inner::after {
    bottom: 0;
    transform: rotate(-90deg);
    transition: bottom 0.1s ease, transform 0.1s 0.14s cubic-bezier(0.215, 0.61, 0.355, 1); }

/*
   * Vortex
   */
.hamburger--vortex .hamburger-inner {
  transition-duration: 0.3s;
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1); }
  .hamburger--vortex .hamburger-inner::before, .hamburger--vortex .hamburger-inner::after {
    transition-duration: 0s;
    transition-delay: 0.1s;
    transition-timing-function: linear; }
  .hamburger--vortex .hamburger-inner::before {
    transition-property: top, opacity; }
  .hamburger--vortex .hamburger-inner::after {
    transition-property: bottom, transform; }

.hamburger--vortex.is-active .hamburger-inner {
  transform: rotate(765deg);
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1); }
  .hamburger--vortex.is-active .hamburger-inner::before, .hamburger--vortex.is-active .hamburger-inner::after {
    transition-delay: 0s; }
  .hamburger--vortex.is-active .hamburger-inner::before {
    top: 0;
    opacity: 0; }
  .hamburger--vortex.is-active .hamburger-inner::after {
    bottom: 0;
    transform: rotate(90deg); }

/*
   * Vortex Reverse
   */
.hamburger--vortex-r .hamburger-inner {
  transition-duration: 0.3s;
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1); }
  .hamburger--vortex-r .hamburger-inner::before, .hamburger--vortex-r .hamburger-inner::after {
    transition-duration: 0s;
    transition-delay: 0.1s;
    transition-timing-function: linear; }
  .hamburger--vortex-r .hamburger-inner::before {
    transition-property: top, opacity; }
  .hamburger--vortex-r .hamburger-inner::after {
    transition-property: bottom, transform; }

.hamburger--vortex-r.is-active .hamburger-inner {
  transform: rotate(-765deg);
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1); }
  .hamburger--vortex-r.is-active .hamburger-inner::before, .hamburger--vortex-r.is-active .hamburger-inner::after {
    transition-delay: 0s; }
  .hamburger--vortex-r.is-active .hamburger-inner::before {
    top: 0;
    opacity: 0; }
  .hamburger--vortex-r.is-active .hamburger-inner::after {
    bottom: 0;
    transform: rotate(-90deg); }

@keyframes pen-animation {
  0% { }
  25% {
    right: 0;
    opacity: 1; }
  100% {
    right: -4rem; } }

@keyframes btn-2-before-animation {
  0% {
    transform: scale3d(0.5, 0.5, 1); }
  50% {
    transform: scale3d(1, 1, 1);
    background-color: rgba(89, 192, 207, 0.5); }
  100% {
    background-color: transparent; } }

@keyframes btn-2-after-animation {
  50% {
    transform: scale3d(1, 1, 1);
    border-color: transparent; }
  51% {
    border-color: rgba(89, 192, 207, 0.5); }
  100% {
    transform: scale3d(1.2, 1.2, 1); } }

@keyframes bounce {
  0% {
    transform: translateY(0.25rem); }
  50% {
    transform: translateY(-0.25rem); }
  100% {
    transform: translateY(0.25rem); } }

@keyframes running-img {
  0% {
    transform: translateX(0) rotate(0.01deg);
    opacity: 1; }
  48% {
    transform: translateX(30%) rotate(0.01deg);
    opacity: 0; }
  53% {
    transform: translateX(-30%) rotate(0.01deg);
    opacity: 0; }
  100% {
    transform: translateX(0) rotate(0.01deg);
    opacity: 1; } }

@keyframes house-svg-animation {
  0% {
    opacity: 0;
    transform: scale(0.8) rotate(0.01deg); }
  50% {
    opacity: 1; }
  100% {
    opacity: 0;
    transform: scale(1.2) translate3d(1.5rem, -1.5rem, 0) rotate(-0.01deg); } }

.v-fade-in-transition {
  transition: all .3s ease; }

.v-fade-in-enter {
  opacity: 0; }

.v-fade-in-leave {
  display: none; }

/*
==============================================
pulse
==============================================
*/
.vip-pulse {
  color: white;
  animation-name: vip-pulse, vip-rotate;
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
  display: inline-block;
  padding-left: 10px;
  padding-right: 10px; }

@keyframes vip-pulse {
  0% {
    opacity: 1; }
  50% {
    opacity: 0.7; }
  100% {
    opacity: 1; } }

@keyframes vip-rotate {
  0% {
    transform: scale(1.2) rotate(0deg); }
  33.33% {
    transform: rotate(-5deg); }
  66.66% {
    transform: rotate(5deg); }
  100% {
    transform: scale(1.2) rotate(0deg); } }

.drop-element, .drop-element:after, .drop-element:before, .drop-element *, .drop-element *:after, .drop-element *:before {
  box-sizing: border-box; }

.drop-element {
  position: absolute;
  display: none; }

.drop-element.drop-open {
  display: block; }

.drop-element.drop-theme-arrows-bounce-dark {
  max-width: 100%;
  max-height: 100%; }

.drop-element.drop-theme-arrows-bounce-dark .drop-content {
  border-radius: 5px;
  position: relative;
  font-family: inherit;
  background: #3e8690;
  color: #fff;
  padding: 1em;
  font-size: 1.1em;
  line-height: 1.5em; }

.drop-element.drop-theme-arrows-bounce-dark .drop-content:before {
  content: "";
  display: block;
  position: absolute;
  width: 0;
  height: 0;
  border-color: transparent;
  border-width: 12px;
  border-style: solid; }

.drop-element.drop-theme-arrows-bounce-dark.drop-element-attached-bottom.drop-element-attached-center .drop-content {
  margin-bottom: 12px; }

.drop-element.drop-theme-arrows-bounce-dark.drop-element-attached-bottom.drop-element-attached-center .drop-content:before {
  top: 100%;
  left: 50%;
  margin-left: -12px;
  border-top-color: #3e8690; }

.drop-element.drop-theme-arrows-bounce-dark.drop-element-attached-top.drop-element-attached-center .drop-content {
  margin-top: 12px; }

.drop-element.drop-theme-arrows-bounce-dark.drop-element-attached-top.drop-element-attached-center .drop-content:before {
  bottom: 100%;
  left: 50%;
  margin-left: -12px;
  border-bottom-color: #3e8690; }

.drop-element.drop-theme-arrows-bounce-dark.drop-element-attached-right.drop-element-attached-middle .drop-content {
  margin-right: 12px; }

.drop-element.drop-theme-arrows-bounce-dark.drop-element-attached-right.drop-element-attached-middle .drop-content:before {
  left: 100%;
  top: 50%;
  margin-top: -12px;
  border-left-color: #3e8690; }

.drop-element.drop-theme-arrows-bounce-dark.drop-element-attached-left.drop-element-attached-middle .drop-content {
  margin-left: 12px; }

.drop-element.drop-theme-arrows-bounce-dark.drop-element-attached-left.drop-element-attached-middle .drop-content:before {
  right: 100%;
  top: 50%;
  margin-top: -12px;
  border-right-color: #3e8690; }

.drop-element.drop-theme-arrows-bounce-dark.drop-element-attached-top.drop-element-attached-left.drop-target-attached-bottom .drop-content {
  margin-top: 12px; }

.drop-element.drop-theme-arrows-bounce-dark.drop-element-attached-top.drop-element-attached-left.drop-target-attached-bottom .drop-content:before {
  bottom: 100%;
  left: 12px;
  border-bottom-color: #3e8690; }

.drop-element.drop-theme-arrows-bounce-dark.drop-element-attached-top.drop-element-attached-right.drop-target-attached-bottom .drop-content {
  margin-top: 12px; }

.drop-element.drop-theme-arrows-bounce-dark.drop-element-attached-top.drop-element-attached-right.drop-target-attached-bottom .drop-content:before {
  bottom: 100%;
  right: 12px;
  border-bottom-color: #3e8690; }

.drop-element.drop-theme-arrows-bounce-dark.drop-element-attached-bottom.drop-element-attached-left.drop-target-attached-top .drop-content {
  margin-bottom: 12px; }

.drop-element.drop-theme-arrows-bounce-dark.drop-element-attached-bottom.drop-element-attached-left.drop-target-attached-top .drop-content:before {
  top: 100%;
  left: 12px;
  border-top-color: #3e8690; }

.drop-element.drop-theme-arrows-bounce-dark.drop-element-attached-bottom.drop-element-attached-right.drop-target-attached-top .drop-content {
  margin-bottom: 12px; }

.drop-element.drop-theme-arrows-bounce-dark.drop-element-attached-bottom.drop-element-attached-right.drop-target-attached-top .drop-content:before {
  top: 100%;
  right: 12px;
  border-top-color: #3e8690; }

.drop-element.drop-theme-arrows-bounce-dark.drop-element-attached-top.drop-element-attached-right.drop-target-attached-left .drop-content {
  margin-right: 12px; }

.drop-element.drop-theme-arrows-bounce-dark.drop-element-attached-top.drop-element-attached-right.drop-target-attached-left .drop-content:before {
  top: 12px;
  left: 100%;
  border-left-color: #3e8690; }

.drop-element.drop-theme-arrows-bounce-dark.drop-element-attached-top.drop-element-attached-left.drop-target-attached-right .drop-content {
  margin-left: 12px; }

.drop-element.drop-theme-arrows-bounce-dark.drop-element-attached-top.drop-element-attached-left.drop-target-attached-right .drop-content:before {
  top: 12px;
  right: 100%;
  border-right-color: #3e8690; }

.drop-element.drop-theme-arrows-bounce-dark.drop-element-attached-bottom.drop-element-attached-right.drop-target-attached-left .drop-content {
  margin-right: 12px; }

.drop-element.drop-theme-arrows-bounce-dark.drop-element-attached-bottom.drop-element-attached-right.drop-target-attached-left .drop-content:before {
  bottom: 12px;
  left: 100%;
  border-left-color: #3e8690; }

.drop-element.drop-theme-arrows-bounce-dark.drop-element-attached-bottom.drop-element-attached-left.drop-target-attached-right .drop-content {
  margin-left: 12px; }

.drop-element.drop-theme-arrows-bounce-dark.drop-element-attached-bottom.drop-element-attached-left.drop-target-attached-right .drop-content:before {
  bottom: 12px;
  right: 100%;
  border-right-color: #3e8690; }

.drop-element.drop-theme-arrows-bounce-dark {
  transform: translateZ(0);
  transition: opacity 100ms;
  opacity: 0; }

.drop-element.drop-theme-arrows-bounce-dark .drop-content {
  transition: transform 0.3s cubic-bezier(0, 0, 0.265, 1.55);
  transform: scale(0) translateZ(0); }

.drop-element.drop-theme-arrows-bounce-dark.drop-open {
  display: none; }

.drop-element.drop-theme-arrows-bounce-dark.drop-open-transitionend {
  display: block; }

.drop-element.drop-theme-arrows-bounce-dark.drop-after-open {
  transition: none;
  opacity: 1; }

.drop-element.drop-theme-arrows-bounce-dark.drop-after-open .drop-content {
  transform: scale(1) translateZ(0); }

.drop-element.drop-theme-arrows-bounce-dark.drop-element-attached-bottom.drop-element-attached-center .drop-content {
  transform-origin: 50% calc(100% + 12px); }

.drop-element.drop-theme-arrows-bounce-dark.drop-element-attached-top.drop-element-attached-center .drop-content {
  transform-origin: 50% -12px; }

.drop-element.drop-theme-arrows-bounce-dark.drop-element-attached-right.drop-element-attached-middle .drop-content {
  transform-origin: calc(100% + 12px) 50%; }

.drop-element.drop-theme-arrows-bounce-dark.drop-element-attached-left.drop-element-attached-middle .drop-content {
  transform-origin: -12px 50%; }

.drop-element.drop-theme-arrows-bounce-dark.drop-element-attached-top.drop-element-attached-left.drop-target-attached-bottom .drop-content {
  transform-origin: 0 -12px; }

.drop-element.drop-theme-arrows-bounce-dark.drop-element-attached-top.drop-element-attached-right.drop-target-attached-bottom .drop-content {
  transform-origin: 100% -12px; }

.drop-element.drop-theme-arrows-bounce-dark.drop-element-attached-bottom.drop-element-attached-left.drop-target-attached-top .drop-content {
  transform-origin: 0 calc(100% + 12px); }

.drop-element.drop-theme-arrows-bounce-dark.drop-element-attached-bottom.drop-element-attached-right.drop-target-attached-top .drop-content {
  transform-origin: 100% calc(100% + 12px); }

.drop-element.drop-theme-arrows-bounce-dark.drop-element-attached-top.drop-element-attached-right.drop-target-attached-left .drop-content {
  transform-origin: calc(100% + 12px) 0; }

.drop-element.drop-theme-arrows-bounce-dark.drop-element-attached-top.drop-element-attached-left.drop-target-attached-right .drop-content {
  transform-origin: -12px 0; }

.drop-element.drop-theme-arrows-bounce-dark.drop-element-attached-bottom.drop-element-attached-right.drop-target-attached-left .drop-content {
  transform-origin: calc(100% + 12px) 100%; }

.drop-element.drop-theme-arrows-bounce-dark.drop-element-attached-bottom.drop-element-attached-left.drop-target-attached-right .drop-content {
  transform-origin: -12px 100%; }

.race-type-select-container {
  width: 100%; }
  .race-type-select-container .race-type-select-wrapper .col {
    padding-top: 4rem;
    padding-bottom: 1rem; }
    @media screen and (max-width: 642px) {
      .race-type-select-container .race-type-select-wrapper .col .content-container .heading {
        font-size: 2rem;
        padding-bottom: 1.5rem;
        padding-top: 0.5rem; } }

.form--credit-card {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -ms-flex-pack: justify;
  justify-content: space-between; }
  .form--credit-card .form-control {
    width: 100%; }
  .form--credit-card .card-number {
    -ms-flex: 1 1 55%;
    flex: 1 1 55%;
    padding-left: 1rem;
    padding-right: 1rem; }
    @media screen and (max-width: 642px) {
      .form--credit-card .card-number {
        -ms-flex: 1 1 100%;
        flex: 1 1 100%; } }
  .form--credit-card .card-expiry {
    -ms-flex: 1 1 35%;
    flex: 1 1 35%;
    padding-left: 1rem;
    padding-right: 1rem; }
    @media screen and (max-width: 642px) {
      .form--credit-card .card-expiry {
        -ms-flex: 1 1 100%;
        flex: 1 1 100%; } }
  .form--credit-card .card-name {
    -ms-flex: 1 0 55%;
    flex: 1 0 55%;
    padding-left: 1rem;
    padding-right: 1rem; }
    @media screen and (max-width: 642px) {
      .form--credit-card .card-name {
        padding-right: 2.5px; } }
  .form--credit-card .card-cvc {
    -ms-flex: 1 0 35%;
    flex: 1 0 35%;
    padding-left: 1rem;
    padding-right: 1rem; }
    @media screen and (max-width: 642px) {
      .form--credit-card .card-cvc {
        padding-left: 2.5px; } }

@media screen and (max-width: 642px) {
  .swal2-content .jp-card-container {
    transform: scale(0.4);
    transform-origin: 40% 50%;
    margin-top: -70px;
    margin-bottom: -70px; } }

@media screen and (max-width: 642px) {
  .scc-subheading {
    display: none; } }

@media screen and (max-width: 642px) {
  body .team-registrant-card-showedit-wrapper .card-body .half {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%; } }

@media screen and (max-width: 642px) {
  .team-registrant-card-new-wrapper .step-control {
    width: 100%; } }

body .swal2-modal {
  font-family: inherit; }
  @media screen and (max-width: 768px) {
    body .swal2-modal {
      padding: 10px !important; } }
  body .swal2-modal h2 {
    color: #484848;
    font-weight: 200;
    padding-bottom: 1rem;
    line-height: 1.5; }

body .swal2-content {
  line-height: 1.5; }
  @media screen and (max-width: 768px) {
    body .swal2-content {
      font-size: 14px; } }

.content-wrapper {
  background-color: #242e32;
  background-image: url("/assets/img/bg-baycity.jpg");
  background-position: 50% 0;
  background-repeat: no-repeat;
  min-height: calc(100vh - 134.45px); }
  @media (max-width: 1200px) {
    .content-wrapper {
      min-height: calc(100vh - 131.81px); } }
  @media (max-width: 768px) {
    .content-wrapper {
      min-height: calc(100vh - 131px); } }

.wrapper {
  max-width: 1920px;
  margin: 0 auto; }

.login-content {
  color: #ffffff;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  padding-top: 4rem;
  padding-bottom: 4rem; }
  .login-content .right, .login-content .left {
    padding-top: 4rem;
    padding-bottom: 4rem;
    -ms-flex: 0 1 35%;
    flex: 0 1 35%; }
    @media screen and (max-width: 642px) {
      .login-content .right, .login-content .left {
        padding-top: 2rem;
        padding-bottom: 2rem; } }
    .login-content .right .heading, .login-content .left .heading {
      text-align: center;
      max-width: 600px;
      position: relative;
      font-size: 4rem;
      margin-bottom: 4rem; }
      .login-content .right .heading i, .login-content .left .heading i {
        font-size: 6rem;
        display: block;
        margin-bottom: 1rem; }
    @media screen and (max-width: 768px) {
      .login-content .right, .login-content .left {
        -ms-flex: 0 1 100%;
        flex: 0 1 100%; } }
  @media screen and (max-width: 642px) {
    .login-content .left .body {
      display: none; } }
  .login-content .v-divider {
    border-left: 1px solid #59c0cf;
    width: 1px; }
    @media screen and (max-width: 768px) {
      .login-content .v-divider {
        border-top: 1px solid #59c0cf;
        width: 100%;
        height: 1px; } }
  .login-content .not-sure {
    font-weight: normal;
    line-height: 1.2;
    -ms-flex: 0 1 50%;
    flex: 0 1 50%;
    text-transform: uppercase;
    text-align: center;
    padding-top: 4rem;
    padding-bottom: 4rem; }
    @media screen and (max-width: 1200px) {
      .login-content .not-sure {
        -ms-flex: 0 1 75%;
        flex: 0 1 75%; } }
    @media screen and (max-width: 768px) {
      .login-content .not-sure {
        -ms-flex: 0 1 100%;
        flex: 0 1 100%; } }
    .login-content .not-sure .not-sure-heading {
      font-size: 1rem;
      color: #ffffff;
      padding-bottom: 1rem; }
    .login-content .not-sure p {
      color: #59c0cf; }
    .login-content .not-sure a {
      text-decoration: underline; }
  .login-content .form .form-control {
    width: 100%; }
  .login-content .form .checkbox .control-label {
    color: #59c0cf; }
  .login-content .right {
    text-align: center; }
    @media screen and (max-width: 642px) {
      .login-content .right .body {
        display: none; } }
    .login-content .right .btn-group {
      margin-top: 1rem; }
    .login-content .right .btn {
      text-align: left;
      text-transform: uppercase;
      margin-bottom: 2rem;
      max-width: 300px;
      display: block;
      margin-left: auto;
      margin-right: auto; }
      .login-content .right .btn i {
        text-align: center;
        width: 3rem;
        display: inline-block; }
    .login-content .right .feedback {
      margin-top: 2rem;
      display: none; }
    .login-content .right .large_team_selection {
      max-width: 400px;
      margin: auto; }
      .login-content .right .large_team_selection li {
        font-weight: normal;
        margin-left: auto;
        margin-right: auto;
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
        border: 2px solid #59c0cf; }
        .login-content .right .large_team_selection li a {
          padding-top: 1rem;
          padding-bottom: 1rem;
          width: 100%;
          display: inline-block; }
        .login-content .right .large_team_selection li:last-of-type {
          margin-top: 1.5rem; }
    .login-content .right .large_team_selection__info {
      font-weight: normal;
      line-height: 1.4;
      color: #59c0cf; }
  .login-content .forget-password-link {
    display: inline-block;
    padding-top: 1.5rem;
    color: #59c0cf;
    text-decoration: underline; }
  .login-content #show-login-btn {
    display: none;
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    transition: transform 0.3s ease-in-out; }
    @media screen and (max-width: 642px) {
      .login-content #show-login-btn {
        display: block; } }
  .login-content #show-signup-btn {
    display: none;
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    transition: transform 0.3s ease-in-out; }
    @media screen and (max-width: 642px) {
      .login-content #show-signup-btn {
        display: block; } }

.page {
  color: #ffffff;
  padding-top: 4rem;
  padding-bottom: 4rem; }
  @media screen and (max-width: 642px) {
    .page {
      padding-top: 1rem;
      padding-bottom: 1rem; } }
  .page .heading {
    text-align: center;
    font-size: 4rem;
    margin-bottom: 4rem; }
    .page .heading i {
      font-size: 6rem;
      display: block;
      margin-bottom: 1rem; }
  .page .body form {
    max-width: 400px;
    margin-left: auto;
    margin-right: auto; }
  .page .body .form-control {
    width: 100%; }

.home-content {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -ms-flex-pack: center;
  justify-content: center;
  padding-top: 4rem;
  padding-bottom: 4rem; }
  .home-content .left {
    -ms-flex: 0 1 40%;
    flex: 0 1 40%; }
    @media screen and (max-width: 768px) {
      .home-content .left {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%; } }
  .home-content .right {
    -ms-flex: 0 1 40%;
    flex: 0 1 40%;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-flow: column nowrap;
    flex-flow: column nowrap;
    -ms-flex-pack: center;
    justify-content: center; }
    @media screen and (max-width: 768px) {
      .home-content .right {
        -ms-flex: 0 0 90%;
        flex: 0 0 90%; } }
  .home-content .reg-card {
    border: 2px solid #59c0cf;
    padding: 4rem;
    text-align: center;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-flow: column nowrap;
    flex-flow: column nowrap; }
    @media screen and (max-width: 642px) {
      .home-content .reg-card {
        padding: 4rem 3rem; } }
    .home-content .reg-card img {
      height: 80px; }
    .home-content .reg-card p {
      color: #ffffff;
      font-size: 4rem;
      padding-top: 4rem;
      padding-bottom: 4rem; }
      @media screen and (max-width: 642px) {
        .home-content .reg-card p {
          font-size: 3rem; } }
    .home-content .reg-card button {
      margin-bottom: 1rem;
      font-size: 1rem;
      text-transform: uppercase;
      text-align: left; }
      .home-content .reg-card button i {
        font-size: 1.5rem;
        min-width: 4rem;
        display: inline-block;
        text-align: center; }
  .home-content .menu-card {
    font-size: 3rem;
    color: #ffffff;
    padding-top: 4rem;
    padding-bottom: 4rem;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -ms-flex-pack: distribute;
    justify-content: space-around;
    -ms-flex-align: center;
    align-items: center; }
    .home-content .menu-card i {
      -ms-flex: 0 0 30%;
      flex: 0 0 30%;
      font-size: 6rem;
      transition: transform 0.5s ease-in-out;
      transform-origin: 50% 50%; }
      @media screen and (max-width: 642px) {
        .home-content .menu-card i {
          font-size: 4rem; } }
    .home-content .menu-card:hover i {
      transform: scale(1.1); }
    .home-content .menu-card p {
      -ms-flex: 0 0 65%;
      flex: 0 0 65%; }
      @media screen and (max-width: 642px) {
        .home-content .menu-card p {
          font-size: 2rem; } }
  .home-content .divider {
    text-align: center;
    border-bottom: 1px solid #59c0cf;
    height: 1.8rem;
    color: #59c0cf; }
    .home-content .divider span {
      padding: 0 1rem;
      font-size: 3rem;
      background-color: #0d3855; }

.active-registrations-content {
  color: #ffffff; }
  .active-registrations-content .info-text {
    font-size: 1.5rem; }
  .active-registrations-content h1 {
    font-size: 3rem;
    text-align: center;
    padding-bottom: 4rem; }
  .active-registrations-content .inner {
    padding-top: 4rem;
    padding-bottom: 4rem;
    max-width: 1200px;
    margin: 0 auto; }
  .active-registrations-content .record-container {
    border-radius: 5px;
    overflow: hidden;
    margin-top: 2rem; }
  .active-registrations-content .registration-brief {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -ms-flex-pack: justify;
    justify-content: space-between;
    color: #2b5A61;
    font-size: 1.5rem;
    background-color: #FFF; }
    @media screen and (max-width: 642px) {
      .active-registrations-content .registration-brief div:nth-child(1), .active-registrations-content .registration-brief div:nth-child(3) {
        display: none; } }
    .active-registrations-content .registration-brief .info {
      padding-left: 1rem;
      padding-top: 1rem;
      padding-bottom: 1rem;
      -ms-flex: 1 0 20%;
      flex: 1 0 20%; }
    .active-registrations-content .registration-brief .expand-btn {
      -ms-flex-positive: 0;
      flex-grow: 0;
      -ms-flex-negative: 0;
      flex-shrink: 0;
      padding: 1rem 1.5rem;
      text-align: center;
      background-color: #3e8690;
      color: #FFF;
      cursor: pointer; }
  .active-registrations-content .registration-details {
    background-color: transparent;
    border-left: 2px solid #3e8690;
    border-right: 2px solid #3e8690;
    border-bottom: 2px solid #3e8690;
    border-radius: 0 0 5px 5px;
    overflow: hidden;
    color: #ffffff;
    font-size: 1.5rem;
    font-weight: normal; }
    .active-registrations-content .registration-details table {
      width: 100%; }
      .active-registrations-content .registration-details table tr:first-of-type {
        border-bottom: 2px solid #3e8690;
        color: #59c0cf;
        text-transform: uppercase;
        font-size: 1rem; }
      .active-registrations-content .registration-details table td {
        width: 25%;
        padding-top: 1rem;
        padding-bottom: 1rem;
        padding-left: 1rem; }
      .active-registrations-content .registration-details table .external-registrant-heading-td {
        border-bottom: 2px solid #3e8690;
        color: #59c0cf;
        font-size: 1rem; }
    .active-registrations-content .registration-details .no-registration {
      padding: 1rem;
      line-height: 1.8; }
    .active-registrations-content .registration-details .general-info-row {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-flow: row wrap;
      flex-flow: row wrap;
      -ms-flex-pack: justify;
      justify-content: space-between;
      font-weight: normal;
      padding-left: 2rem;
      padding-right: 2rem;
      padding-top: 2rem;
      padding-bottom: 1rem; }
      .active-registrations-content .registration-details .general-info-row .third {
        -ms-flex: 0 0 30%;
        flex: 0 0 30%; }
        @media screen and (max-width: 768px) {
          .active-registrations-content .registration-details .general-info-row .third {
            -ms-flex: 1 0 100%;
            flex: 1 0 100%; } }
      .active-registrations-content .registration-details .general-info-row .separator {
        width: 100%;
        height: 2px;
        border-top: 2px solid #59c0cf; }
      .active-registrations-content .registration-details .general-info-row .details-list li {
        padding-top: 0.5rem; }
        .active-registrations-content .registration-details .general-info-row .details-list li .details-heading {
          float: left;
          color: #59c0cf;
          font-size: 1rem;
          text-transform: uppercase; }
        .active-registrations-content .registration-details .general-info-row .details-list li .details-content {
          clear: both;
          padding-top: 0.5rem; }
          .active-registrations-content .registration-details .general-info-row .details-list li .details-content--text-lg {
            font-size: 4rem; }
        .active-registrations-content .registration-details .general-info-row .details-list li .details-icon {
          color: #59c0cf;
          float: right; }
        .active-registrations-content .registration-details .general-info-row .details-list li .details-divider {
          border-bottom: 1px solid #3e8690;
          padding-top: 2rem; }
      .active-registrations-content .registration-details .general-info-row .details-avatar-icon {
        text-align: center;
        padding: 2rem;
        font-size: 10rem;
        animation: running-img 8s infinite linear; }
    .active-registrations-content .registration-details .add-more {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-flow: row nowrap;
      flex-flow: row nowrap;
      -ms-flex-pack: justify;
      justify-content: space-between;
      font-weight: 200;
      background-color: #3e8690; }
      .active-registrations-content .registration-details .add-more .add-more-label {
        padding: 1rem; }
      .active-registrations-content .registration-details .add-more .add-more-link {
        padding: 1rem 1.5rem; }
    .active-registrations-content .registration-details .delete-team {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-flow: row nowrap;
      flex-flow: row nowrap;
      -ms-flex-pack: justify;
      justify-content: space-between;
      cursor: pointer;
      font-weight: 200;
      background-color: #2b5A61; }
      .active-registrations-content .registration-details .delete-team-label {
        padding: 1rem; }
      .active-registrations-content .registration-details .delete-team-link {
        padding: 1rem 1.5rem; }
    .active-registrations-content .registration-details .export-team {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-flow: row nowrap;
      flex-flow: row nowrap;
      -ms-flex-pack: justify;
      justify-content: space-between;
      cursor: pointer;
      font-weight: 200;
      background-color: #2b5A61; }
      .active-registrations-content .registration-details .export-team-label {
        padding: 1rem; }
      .active-registrations-content .registration-details .export-team-link {
        padding: 1rem 1.5rem; }
  .active-registrations-content .reg-card {
    border: 2px solid #59c0cf;
    padding: 2rem;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -ms-flex-pack: justify;
    justify-content: space-between; }
    @media screen and (max-width: 642px) {
      .active-registrations-content .reg-card {
        padding: 4rem 3rem; } }
    .active-registrations-content .reg-card svg {
      margin-left: auto;
      -ms-flex: 0 1 250px;
      flex: 0 1 250px;
      height: 100px; }
      @media screen and (max-width: 1200px) {
        .active-registrations-content .reg-card svg {
          -ms-flex-preferred-size: 100%;
          flex-basis: 100%; } }
    .active-registrations-content .reg-card p {
      -ms-flex: 0 1 100%;
      flex: 0 1 100%;
      text-align: center;
      color: #ffffff;
      font-size: 3rem;
      padding-top: 1.5rem;
      padding-bottom: 1.5rem; }
      @media screen and (max-width: 1200px) {
        .active-registrations-content .reg-card p {
          -ms-flex-preferred-size: 100%;
          flex-basis: 100%;
          text-align: center; } }
    .active-registrations-content .reg-card .form {
      -ms-flex: 0 1 30%;
      flex: 0 1 30%; }
      @media screen and (max-width: 1200px) {
        .active-registrations-content .reg-card .form {
          -ms-flex-preferred-size: 100%;
          flex-basis: 100%; } }
      .active-registrations-content .reg-card .form .form-control {
        width: 100%; }
    .active-registrations-content .reg-card .btn {
      padding: 0.75rem;
      -ms-flex: 0 1 auto;
      flex: 0 1 auto;
      width: 45%;
      margin-top: 1.5rem;
      font-size: 1rem;
      text-transform: uppercase;
      text-align: left; }
      @media screen and (max-width: 1200px) {
        .active-registrations-content .reg-card .btn {
          -ms-flex-preferred-size: 100%;
          flex-basis: 100%; } }
      .active-registrations-content .reg-card .btn i {
        font-size: 2rem;
        min-width: 4rem;
        display: inline-block;
        text-align: center; }
      .active-registrations-content .reg-card .btn span {
        position: relative;
        top: -0.25rem;
        left: 0.25rem; }

.reg {
  color: #ffffff;
  padding-top: 4rem;
  padding-bottom: 4rem; }
  .reg h1 {
    font-size: 3rem;
    text-align: center;
    padding-bottom: 3rem; }
    @media screen and (max-width: 768px) {
      .reg h1 {
        font-size: 2rem; } }
    @media screen and (max-width: 642px) {
      .reg h1 {
        padding-bottom: 2rem; } }
    .reg h1 small {
      font-size: 1.5rem;
      font-weight: 100;
      display: block;
      margin-top: 1rem; }
      .reg h1 small:before {
        content: '-';
        padding-right: 1rem; }
      .reg h1 small:after {
        content: '-';
        padding-left: 1rem; }
  .reg .unsaved-heading {
    text-transform: uppercase;
    width: 100%;
    border-top: 2px solid #59c0cf;
    padding-top: 1rem;
    padding-bottom: 2rem; }
  .reg .form {
    display: block;
    max-width: 1000px;
    margin: 0 auto; }
    .reg .form .form-control {
      width: 100%; }
  .reg .cards-container {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -ms-flex-pack: justify;
    justify-content: space-between;
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto; }
    .reg .cards-container .full-card {
      padding-bottom: 2rem;
      -ms-flex: 1 1 100%;
      flex: 1 1 100%; }
    .reg .cards-container .one-third-card {
      padding-bottom: 2rem;
      -ms-flex: 0 1 31%;
      flex: 0 1 31%; }
      @media screen and (max-width: 1200px) {
        .reg .cards-container .one-third-card {
          -ms-flex: 0 1 47%;
          flex: 0 1 47%; } }
      @media screen and (max-width: 642px) {
        .reg .cards-container .one-third-card {
          -ms-flex: 1 1 100%;
          flex: 1 1 100%; } }
    .reg .cards-container .one-third-card-placeholder {
      -ms-flex: 0 1 31%;
      flex: 0 1 31%; }
      @media screen and (max-width: 1200px) {
        .reg .cards-container .one-third-card-placeholder {
          display: none; } }
    .reg .cards-container .price-summary {
      -ms-flex: 1 1 100%;
      flex: 1 1 100%; }
  .reg .info-text-after-heading, .reg .info-text-after-heading--wide {
    max-width: 1200px;
    margin: auto;
    line-height: 1.5;
    font-size: 1.2rem;
    margin-bottom: 2rem;
    text-align: center;
    color: #59c0cf;
    background-color: rgba(89, 192, 207, 0.1);
    border: 2px solid #59c0cf;
    padding: 1rem; }
    .reg .info-text-after-heading--wide {
      max-width: 1000px; }

.reg.reg-individual-content .form {
  max-width: 1200px; }

.reg.reg-individual-content .pay-btn-container {
  max-width: 1200px;
  margin: 0 auto; }

.reg.reg-individual-content .individual-confirm-wrapper {
  max-width: 1200px;
  margin: auto;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -ms-flex-pack: justify;
  justify-content: space-between; }
  .reg.reg-individual-content .individual-confirm-wrapper .individual-confirm__left, .reg.reg-individual-content .individual-confirm-wrapper .individual-confirm__right {
    margin-top: 1rem;
    margin-bottom: 1rem;
    -ms-flex: 1 1 100%;
    flex: 1 1 100%; }

.reg.reg-smallteam-content .form {
  max-width: 1200px; }

.reg.reg-largeteam-content .form {
  max-width: 1200px; }

.medal-insert-help, .vip-help {
  font-size: 2rem;
  position: relative;
  top: 0.25rem; }

.edit-basic-info {
  color: #ffffff;
  padding-top: 4rem;
  padding-bottom: 4rem; }
  .edit-basic-info h1 {
    font-size: 3rem;
    text-align: center;
    padding-bottom: 4rem;
    padding-top: 4rem; }
  .edit-basic-info .form {
    display: block;
    margin: auto;
    max-width: 600px; }
    .edit-basic-info .form .form-control {
      width: 100%; }

.loading-wrapper {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: #fff;
  z-index: 99999;
  opacity: 1; }
  .loading-wrapper.finished {
    visibility: hidden;
    opacity: 0;
    -webkit-backface-visibility: hidden;
    transition: opacity 0.6s ease-in-out, visibility 0.6s ease-in-out; }
    .loading-wrapper.finished .loading {
      opacity: 0;
      transition: opacity 0.6s ease-in-out; }
  .loading-wrapper .loading {
    position: fixed;
    top: 50%;
    left: 50%;
    margin: -40px 0 0 -40px;
    height: 50px;
    width: 50px;
    border-radius: 50%;
    border: 5px solid #3e8690;
    border-top-color: #59c0cf;
    animation: spin 1s infinite linear; }
    .loading-wrapper .loading:before {
      content: '';
      position: absolute;
      top: 5px;
      left: 5px;
      height: 30px;
      width: 30px;
      border-radius: 50%;
      border: 5px solid #3e8690;
      border-top-color: #59c0cf;
      animation: counter-spin .5s infinite linear; }
    .loading-wrapper .loading:after {
      content: '';
      position: absolute;
      top: 15px;
      left: 15px;
      height: 10px;
      width: 10px;
      border-radius: 50%;
      border: 5px solid #3e8690;
      border-top-color: #59c0cf;
      animation: spin 1s infinite linear; }

.payment-table th {
  padding: 1rem 0.5rem;
  border-bottom: 2px solid #59c0cf; }

.payment-table td {
  padding: 0.75rem 0.25rem;
  text-align: center; }

.payment-table thead {
  font-size: 1.5rem;
  text-transform: uppercase;
  color: #59c0cf; }

@keyframes spin {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

@keyframes counter-spin {
  0% {
    transform: rotate(360deg); }
  100% {
    transform: rotate(0deg); } }

@font-face {
  font-family: "CirceLight";
  src: url("/fonts/Circe-Light.ttf"); }

@font-face {
  font-family: "CirceRegular";
  src: url("/fonts/Circe-Regular.ttf"); }

@font-face {
  font-family: "CirceBold";
  src: url("/fonts/Circe-Bold.ttf"); }

.admin-content-wrapper {
  background-color: #f5f5f5 !important;
  padding-bottom: 4rem;
  min-height: calc(100vh - 180px - 95px);
  font-size: 1.5rem; }
  .admin-content-wrapper .admin-individual {
    font-size: 1rem;
    color: #fff; }
    .admin-content-wrapper .admin-individual .form-control {
      width: 100%; }
  .admin-content-wrapper h3 {
    font-family: "CirceBold";
    font-size: 2rem !important;
    padding: 3rem 0 !important;
    padding-bottom: 1rem !important;
    color: #113d5a !important; }
    @media (max-width: 991px) {
      .admin-content-wrapper h3 {
        font-size: 1.5rem !important; } }
    @media (max-width: 767px) {
      .admin-content-wrapper h3 {
        font-size: 20px !important; } }
  .admin-content-wrapper .panel {
    background-color: #ffffff;
    margin-bottom: 2rem;
    margin-top: 2rem; }
    .admin-content-wrapper .panel .panel-heading {
      font-size: 1.5rem;
      padding: 1rem;
      background-color: #3e8690;
      color: #ffffff;
      text-transform: uppercase; }
    .admin-content-wrapper .panel .panel-body.panel-inner {
      padding: 1rem; }
  .admin-content-wrapper .registrants-summary {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin: 2rem 0; }
    .admin-content-wrapper .registrants-summary .card {
      -ms-flex: 1 1 25%;
      flex: 1 1 25%;
      padding: 2rem; }
      .admin-content-wrapper .registrants-summary .card.card-1 {
        background-color: #59c0cf;
        color: #2b5A61; }
      .admin-content-wrapper .registrants-summary .card.card-2 {
        background-color: #3e8690;
        color: #ffffff; }
      .admin-content-wrapper .registrants-summary .card.card-3 {
        background-color: #ffffff;
        color: #2b5A61; }
      .admin-content-wrapper .registrants-summary .card.card-4 {
        background-color: #2b5A61;
        color: #ffffff; }
      .admin-content-wrapper .registrants-summary .card .icon-row {
        font-size: 6rem;
        transform: rotate(0.01deg);
        transition: transform 2s ease-in-out; }
      .admin-content-wrapper .registrants-summary .card .number-row {
        padding-top: 2rem;
        font-size: 4rem;
        text-align: right;
        transform: rotate(0.01deg);
        transition: transform 2s ease-in-out; }
      .admin-content-wrapper .registrants-summary .card .desc-row {
        text-align: right;
        padding-top: 0.5rem;
        font-weight: normal;
        text-transform: uppercase;
        transform: rotate(0.01deg);
        transition: transform 2s ease-in-out; }
      .admin-content-wrapper .registrants-summary .card:hover .icon-row {
        transform: translateX(2rem); }
      .admin-content-wrapper .registrants-summary .card:hover .number-row {
        transform: translateX(-2rem); }
      .admin-content-wrapper .registrants-summary .card:hover .desc-row {
        transform: translateX(-2rem); }
  .admin-content-wrapper .c-checkbox span, .admin-content-wrapper .c-radio span {
    margin-left: 0; }
  .admin-content-wrapper .table td, .admin-content-wrapper .table th {
    padding: 0.5rem 1rem; }
  .admin-content-wrapper .table thead th {
    text-transform: uppercase;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem; }
  .admin-content-wrapper #datatable2 {
    font-size: 1rem; }
    .admin-content-wrapper #datatable2 [class^=icon-] {
      font-size: 2rem; }
  .admin-content-wrapper .pricing-table .form-control {
    width: 8rem; }
  .admin-content-wrapper .form-group .radio label {
    font-size: 1rem; }
  .admin-content-wrapper .form-group .radio input[type=radio] {
    margin-right: 0.5rem; }

.westpac-banner-link {
  display: block; }

.westpac-banner {
  display: block;
  max-width: 860px !important;
  margin: 0 auto;
  padding-bottom: 30px;
  width: 100%; }

.hidden {
  display: none !important; }

.container {
  max-width: 1170px;
  width: 100%;
  margin: 0 auto;
  padding-left: 15px;
  padding-right: 15px; }
  .container.container-forms {
    padding-top: 80px;
    padding-bottom: 50px;
    font-family: "CirceRegular"; }
  .container > .inner {
    background-color: #ffffff; }
    .container > .inner h1 {
      font-family: "CirceBold";
      color: #000000;
      padding-bottom: 20px; }
    .container > .inner .btn-small {
      border: 2px solid #0089cf;
      background-color: transparent;
      color: #0089cf;
      padding: .5rem 1rem;
      cursor: pointer;
      font-size: 1rem;
      display: inline-block;
      line-height: normal;
      font-family: "CirceRegular";
      transition: all .3s ease-in-out;
      -moz-transition: all .3s ease-in-out;
      -webkit-transition: all .3s ease-in-out; }
      .container > .inner .btn-small:focus, .container > .inner .btn-small:active, .container > .inner .btn-small:hover {
        outline: none; }
      .container > .inner .btn-small:hover {
        color: #ffffff;
        border-color: #414141;
        background-color: #414141; }
    .container > .inner .team-registrant-card-new-wrapper .step-control {
      color: #0089cf;
      background-color: #ffffff;
      border-color: #0089cf;
      font-family: "CirceBold";
      font-size: 18px;
      padding: 10px 15px;
      transition: all .3s ease-in-out;
      -moz-transition: all .3s ease-in-out;
      -webkit-transition: all .3s ease-in-out; }
      .container > .inner .team-registrant-card-new-wrapper .step-control i {
        transition: none;
        font-size: 21px; }
      .container > .inner .team-registrant-card-new-wrapper .step-control:focus, .container > .inner .team-registrant-card-new-wrapper .step-control:active, .container > .inner .team-registrant-card-new-wrapper .step-control:hover {
        outline: none; }
      .container > .inner .team-registrant-card-new-wrapper .step-control:hover {
        color: #ffffff;
        border-color: #414141;
        background-color: #414141; }
      .container > .inner .team-registrant-card-new-wrapper .step-control.is-active {
        color: #ffffff;
        border-color: #113d5a;
        background-color: #113d5a; }
        .container > .inner .team-registrant-card-new-wrapper .step-control.is-active i {
          color: #ffffff;
          transition: all .3s ease-in-out;
          -moz-transition: all .3s ease-in-out;
          -webkit-transition: all .3s ease-in-out; }
        .container > .inner .team-registrant-card-new-wrapper .step-control.is-active:hover {
          color: #ffffff;
          border-color: #0089cf;
          background-color: #0089cf; }
          .container > .inner .team-registrant-card-new-wrapper .step-control.is-active:hover i {
            color: #ffffff; }
    .container > .inner .fetch-previous-info-wrapper .fetch-previous-info-button {
      border: 2px solid #0089cf;
      background-color: transparent;
      color: #0089cf;
      padding: .5rem 1rem;
      cursor: pointer;
      font-size: 1rem;
      display: inline-block;
      line-height: normal;
      font-family: "CirceRegular";
      transition: all .3s ease-in-out;
      -moz-transition: all .3s ease-in-out;
      -webkit-transition: all .3s ease-in-out; }
      .container > .inner .fetch-previous-info-wrapper .fetch-previous-info-button:focus, .container > .inner .fetch-previous-info-wrapper .fetch-previous-info-button:active, .container > .inner .fetch-previous-info-wrapper .fetch-previous-info-button:hover {
        outline: none; }
      .container > .inner .fetch-previous-info-wrapper .fetch-previous-info-button:hover {
        color: #ffffff;
        border-color: #414141;
        background-color: #414141; }
    .container > .inner .unsaved-heading {
      color: #113d5a;
      font-family: "CirceBold";
      text-transform: uppercase;
      letter-spacing: 2px;
      font-size: 18px;
      border-top: 1px solid #d8d8d8;
      padding-top: 10px; }
    .container > .inner .info-text-after-heading, .container > .inner .reg .info-text-after-heading--wide, .reg .container > .inner .info-text-after-heading--wide, .container > .inner .info-text-after-heading--wide {
      color: #414141;
      border-color: #414141;
      background-color: rgba(0, 137, 207, 0.1); }
    .container > .inner .form .form-group label.control-label {
      color: #113d5a;
      line-height: normal; }
      .container > .inner .form .form-group label.control-label i {
        font-size: 1.3rem;
        position: relative;
        top: 2px; }
      .container > .inner .form .form-group label.control-label:after {
        color: #113d5a; }
    @media (max-width: 767px) {
      .container > .inner .form .form-group .form-control.input-filled + label.control-label {
        top: 26% !important; } }
    .container > .inner .form .form-group .form-group__info {
      color: #000000;
      font-family: "CirceLight";
      font-size: 17px; }
    .container > .inner .form .form-group input[type=text].form-control, .container > .inner .form .form-group input[type=email].form-control {
      color: #0089cf;
      font-family: "CirceRegular";
      font-size: 20px;
      padding: 10px 15px;
      padding-bottom: 8px; }
    .container > .inner .form .form-group .form-control {
      border-color: #000000;
      transition: all .3s ease-in-out;
      -moz-transition: all .3s ease-in-out;
      -webkit-transition: all .3s ease-in-out; }
      .container > .inner .form .form-group .form-control:focus, .container > .inner .form .form-group .form-control:active, .container > .inner .form .form-group .form-control:hover {
        border-color: #0089cf; }
      .container > .inner .form .form-group .form-control.has-pen:focus + label.control-label:after {
        color: #113d5a; }
    .container > .inner .form .form-group .jz-select-wrapper .select-heading {
      color: #0089cf;
      font-family: "CirceRegular"; }
      @media (max-width: 767px) {
        .container > .inner .form .form-group .jz-select-wrapper .select-heading {
          top: 26% !important; } }
    .container > .inner .form .form-group .jz-select-wrapper .jz-placeholder {
      border-color: #000000;
      color: #0089cf;
      font-family: "CirceRegular";
      font-size: 18px;
      padding-bottom: 13px; }
      .container > .inner .form .form-group .jz-select-wrapper .jz-placeholder:after {
        color: #000000; }
      .container > .inner .form .form-group .jz-select-wrapper .jz-placeholder:focus {
        border-color: #0089cf; }
        .container > .inner .form .form-group .jz-select-wrapper .jz-placeholder:focus:after {
          color: #0089cf; }
      .container > .inner .form .form-group .jz-select-wrapper .jz-placeholder:focus, .container > .inner .form .form-group .jz-select-wrapper .jz-placeholder:active, .container > .inner .form .form-group .jz-select-wrapper .jz-placeholder:hover {
        outline: none; }
      @media (max-width: 767px) {
        .container > .inner .form .form-group .jz-select-wrapper .jz-placeholder {
          font-size: 14px; } }
    .container > .inner .form .form-group .jz-select-wrapper .jz-options {
      background-color: #f5f5f5;
      border: 2px solid #0089cf;
      border-top: 0px;
      max-height: 200px;
      overflow-y: auto; }
      .container > .inner .form .form-group .jz-select-wrapper .jz-options ul li {
        transition: all .3s ease-in-out;
        -moz-transition: all .3s ease-in-out;
        -webkit-transition: all .3s ease-in-out; }
        .container > .inner .form .form-group .jz-select-wrapper .jz-options ul li:hover {
          color: #ffffff;
          background-color: #0089cf; }
        .container > .inner .form .form-group .jz-select-wrapper .jz-options ul li:focus, .container > .inner .form .form-group .jz-select-wrapper .jz-options ul li:active, .container > .inner .form .form-group .jz-select-wrapper .jz-options ul li:hover {
          outline: none; }
        .container > .inner .form .form-group .jz-select-wrapper .jz-options ul li span {
          font-family: "CirceRegular";
          padding-bottom: 13px; }
          .container > .inner .form .form-group .jz-select-wrapper .jz-options ul li span:focus, .container > .inner .form .form-group .jz-select-wrapper .jz-options ul li span:active, .container > .inner .form .form-group .jz-select-wrapper .jz-options ul li span:hover {
            outline: none; }
          .container > .inner .form .form-group .jz-select-wrapper .jz-options ul li span:hover {
            color: #ffffff;
            background-color: #0089cf; }
    .container > .inner .form .form-group .jz-price-wrapper {
      font-family: "CirceRegular"; }
      .container > .inner .form .form-group .jz-price-wrapper table tbody tr, .container > .inner .form .form-group .jz-price-wrapper table tfoot tr {
        border: 1px solid #000000; }
      .container > .inner .form .form-group .jz-price-wrapper table tbody td, .container > .inner .form .form-group .jz-price-wrapper table tfoot td {
        font-size: 18px; }
      .container > .inner .form .form-group .jz-price-wrapper table tfoot .table-footer-row td {
        font-size: 20px;
        background-color: #0089cf;
        color: #ffffff;
        font-family: "CirceBold"; }
    .container > .inner .form .form-group .radio label {
      color: #0089cf; }
      .container > .inner .form .form-group .radio label span {
        font-family: "CirceRegular";
        padding-left: 2.5rem;
        padding-right: 2rem;
        padding-top: 0.4rem;
        padding-bottom: 0.5rem; }
        .container > .inner .form .form-group .radio label span:before {
          border-color: #000000; }
        .container > .inner .form .form-group .radio label span:after {
          background-color: #0089cf; }
      .container > .inner .form .form-group .radio label input[type='radio']:focus + span::before {
        border-color: #0089cf;
        transform: scale3d(1.1, 1.1, 1); }
    @media (max-width: 767px) {
      .container > .inner .form .form-group .radio {
        display: block;
        width: 100%; } }
    .container > .inner .form .form-group .checkbox label {
      color: #0089cf; }
      .container > .inner .form .form-group .checkbox label span {
        font-family: "CirceLight";
        font-size: 17px; }
        .container > .inner .form .form-group .checkbox label span:before {
          border-color: #000000; }
        .container > .inner .form .form-group .checkbox label span a {
          color: #000000; }
      .container > .inner .form .form-group .checkbox label input[type='checkbox']:focus + span::before {
        border-color: #0089cf;
        transform: scale3d(1.1, 1.1, 1); }
      .container > .inner .form .form-group .checkbox label input[type='checkbox']:checked + span::after {
        color: #0089cf; }
    .container > .inner .form .cov-vue-date .datepickbox .input-addon {
      border-left: 2px solid #000000; }
      .container > .inner .form .cov-vue-date .datepickbox .input-addon .icon-calendar {
        color: #0089cf; }
    .container > .inner .form .cov-vue-date .cov-date-body {
      background-color: #0089cf;
      font-family: "CirceRegular"; }
    .container > .inner .form .form-field {
      border-top: 1px solid #d8d8d8;
      padding-top: 10px; }
      .container > .inner .form .form-field .field-full, .container > .inner .form .form-field .field-left, .container > .inner .form .form-field .field-right {
        width: 100%; }
        @media (max-width: 767px) {
          .container > .inner .form .form-field .field-full, .container > .inner .form .form-field .field-left, .container > .inner .form .form-field .field-right {
            display: block;
            width: 100%; } }
      .container > .inner .form .form-field .field-full {
        width: 100%; }
      .container > .inner .form .form-field .field-heading {
        color: #113d5a;
        font-family: "CirceBold";
        padding-top: 20px;
        text-transform: uppercase;
        letter-spacing: 2px;
        font-size: 18px; }
      @media (max-width: 767px) {
        .container > .inner .form .form-field {
          padding-bottom: 10px; } }
    .container > .inner .form .dogwalk-info {
      background-color: #113d5a;
      color: #ffffff;
      margin-top: 0;
      padding: 20px; }
      .container > .inner .form .dogwalk-info label.control-label {
        color: #ffffff !important;
        font-family: "CirceBold";
        font-size: 26px;
        margin-bottom: 10px; }
      .container > .inner .form .dogwalk-info ul li {
        position: relative;
        padding-left: 25px;
        font-size: 20px;
        margin-bottom: 10px; }
        .container > .inner .form .dogwalk-info ul li:before {
          content: "\f1b0";
          display: inline-block;
          font-family: 'FontAwesome';
          position: absolute;
          left: 0; }
      @media (max-width: 767px) {
        .container > .inner .form .dogwalk-info label.control-label {
          font-size: 20px; }
        .container > .inner .form .dogwalk-info ul li {
          font-size: 16px; } }
    .container > .inner .form .vip-info {
      margin-top: 10px;
      margin-bottom: 10px;
      text-align: center; }
      .container > .inner .form .vip-info label.control-label .vip-pulse {
        color: #E41B13; }
      @media (max-width: 991px) {
        .container > .inner .form .vip-info {
          text-align: left; } }
    .container > .inner .form .expo-info {
      background-color: #113d5a;
      color: #ffffff;
      padding: 20px;
      font-size: 15px; }
      .container > .inner .form .expo-info span {
        padding-left: 60px;
        margin-bottom: 15px;
        display: block;
        min-height: 35px; }
        .container > .inner .form .expo-info span:before {
          content: '';
          display: inline-block;
          width: 50px;
          height: 50px;
          position: absolute;
          left: 20px;
          background-size: contain;
          background-image: url("../assets/img/marker.png"); }
      .container > .inner .form .expo-info .expo-schedule {
        list-style: none; }
        .container > .inner .form .expo-info .expo-schedule li {
          position: relative;
          padding: 0;
          padding-left: 15px;
          margin-bottom: 10px; }
          .container > .inner .form .expo-info .expo-schedule li:before {
            content: "\f111";
            display: inline-block;
            font-size: 8px;
            font-family: 'FontAwesome';
            position: absolute;
            left: 0;
            top: 3px; }
      .container > .inner .form .expo-info.postage span:before {
        background-image: url("../assets/img/message.png"); }
    .container > .inner .form .error-box {
      display: block; }
      .container > .inner .form .error-box .error {
        padding: 10px 15px 10px 0;
        color: #E41B13; }
    .container > .inner .form .race-type-select-container h2 {
      color: #000000;
      font-family: "CirceLight";
      display: block;
      margin-top: 20px;
      margin-bottom: 20px; }
    .container > .inner .form .race-type-select-container .race-type-select-wrapper {
      width: 100%;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-flow: row wrap;
      flex-flow: row wrap;
      -ms-flex-pack: justify;
      justify-content: space-between; }
      .container > .inner .form .race-type-select-container .race-type-select-wrapper .col {
        -ms-flex: 0 0 16%;
        flex: 0 0 16%;
        text-align: center;
        padding-top: 20px; }
        .container > .inner .form .race-type-select-container .race-type-select-wrapper .col .icon-container {
          position: relative;
          display: inline-block;
          font-size: 5rem;
          padding: 1rem;
          margin: auto;
          border-radius: 9999px;
          border: 4px solid #000000;
          transition: all 0.2s ease-in-out;
          width: 100px;
          height: 100px;
          font-size: 3.5rem;
          box-shadow: none !important;
          cursor: pointer;
          transition: all .3s ease-in-out;
          -moz-transition: all .3s ease-in-out;
          -webkit-transition: all .3s ease-in-out; }
          .container > .inner .form .race-type-select-container .race-type-select-wrapper .col .icon-container i {
            color: #000000;
            position: absolute;
            width: 100%;
            height: auto;
            left: 0;
            top: calc(50% - 28px); }
            @media (max-width: 1199px) {
              .container > .inner .form .race-type-select-container .race-type-select-wrapper .col .icon-container i {
                top: calc(50% - 24.5px); } }
          .container > .inner .form .race-type-select-container .race-type-select-wrapper .col .icon-container:hover {
            background-color: #0089cf; }
            .container > .inner .form .race-type-select-container .race-type-select-wrapper .col .icon-container:hover i {
              color: #ffffff; }
        .container > .inner .form .race-type-select-container .race-type-select-wrapper .col .content-container .heading {
          font-size: 1.5rem;
          font-weight: 200;
          padding-bottom: 3rem;
          padding-top: 1rem;
          color: #000000;
          padding-bottom: 20px;
          font-family: "CirceBold"; }
          .container > .inner .form .race-type-select-container .race-type-select-wrapper .col .content-container .heading.dog-walk {
            padding-bottom: 17px; }
          @media (max-width: 767px) {
            .container > .inner .form .race-type-select-container .race-type-select-wrapper .col .content-container .heading {
              font-size: 18px; } }
        .container > .inner .form .race-type-select-container .race-type-select-wrapper .col.focused .icon-container {
          border-color: #000000;
          background-color: #0089cf; }
          .container > .inner .form .race-type-select-container .race-type-select-wrapper .col.focused .icon-container i {
            color: #ffffff; }
        .container > .inner .form .race-type-select-container .race-type-select-wrapper .col.col--active .icon-container {
          transform: scale(1.1);
          border-color: #0ec70b;
          background-color: #0ec70b; }
          .container > .inner .form .race-type-select-container .race-type-select-wrapper .col.col--active .icon-container i {
            color: #ffffff; }
            .container > .inner .form .race-type-select-container .race-type-select-wrapper .col.col--active .icon-container i:before {
              content: '\74';
              font-family: 'icomoon'; }
        .container > .inner .form .race-type-select-container .race-type-select-wrapper .col.col--active .content-container .heading {
          color: #0089cf; }
        @media (max-width: 991px) {
          .container > .inner .form .race-type-select-container .race-type-select-wrapper .col {
            -ms-flex: 0 0 29.333333%;
            flex: 0 0 29.333333%; } }
        @media (max-width: 550px) {
          .container > .inner .form .race-type-select-container .race-type-select-wrapper .col {
            -ms-flex: 0 0 49.333333%;
            flex: 0 0 49.333333%; } }
    .container > .inner .form .race-type-select-container .race-choice-select-wrapper .col-choice {
      height: 0;
      overflow: hidden; }
      .container > .inner .form .race-type-select-container .race-choice-select-wrapper .col-choice .button-container {
        display: table;
        width: 100%;
        table-layout: fixed; }
      .container > .inner .form .race-type-select-container .race-choice-select-wrapper .col-choice .button-radio {
        display: table-cell;
        padding: 1rem 10px;
        text-align: center; }
        .container > .inner .form .race-type-select-container .race-choice-select-wrapper .col-choice .button-radio label span {
          font-size: 16px;
          font-family: "CirceRegular";
          border-color: #000000;
          color: #000000;
          transition: all .3s ease-in-out;
          -moz-transition: all .3s ease-in-out;
          -webkit-transition: all .3s ease-in-out; }
          .container > .inner .form .race-type-select-container .race-choice-select-wrapper .col-choice .button-radio label span:hover {
            color: #ffffff;
            background-color: #0089cf;
            border-color: #0089cf; }
        .container > .inner .form .race-type-select-container .race-choice-select-wrapper .col-choice .button-radio input[type=radio] {
          position: absolute;
          clip: rect(0px 0px 0px 0px); }
          .container > .inner .form .race-type-select-container .race-choice-select-wrapper .col-choice .button-radio input[type=radio] + span {
            display: inline-block;
            margin: auto;
            padding-top: 1rem;
            padding-bottom: 1rem;
            width: 100%;
            transition: all 0.2s ease-in-out;
            cursor: pointer;
            color: #0089cf;
            background-color: #ffffff;
            border: 2px solid #113d5a; }
          .container > .inner .form .race-type-select-container .race-choice-select-wrapper .col-choice .button-radio input[type=radio]:focus + span {
            box-shadow: 0px 0px 0px 2px #0089cf; }
          .container > .inner .form .race-type-select-container .race-choice-select-wrapper .col-choice .button-radio input[type=radio]:checked + span {
            box-shadow: 0px 0px 0px 2px #fff;
            color: #ffffff;
            background-color: #113d5a;
            border-color: #000000; }
        @media (max-width: 767px) {
          .container > .inner .form .race-type-select-container .race-choice-select-wrapper .col-choice .button-radio {
            display: inline-block;
            width: 50%; } }
      .container > .inner .form .race-type-select-container .race-choice-select-wrapper .col-choice.selected {
        height: auto; }
    .container > .inner .form .race-type-select-container .selected-race {
      display: none;
      color: #113d5a;
      font-family: "CirceBold";
      font-size: 30px;
      width: 100%;
      text-align: center;
      padding-top: 30px;
      padding-bottom: 30px; }
      .container > .inner .form .race-type-select-container .selected-race span {
        color: #0089cf; }
      @media (max-width: 767px) {
        .container > .inner .form .race-type-select-container .selected-race {
          font-size: 26px; } }
    .container > .inner .team-registrant-card-showedit-wrapper {
      background-color: #ffffff;
      border: 1px solid grey; }
      .container > .inner .team-registrant-card-showedit-wrapper .card-heading {
        background-color: #000000;
        color: #ffffff;
        padding-bottom: 0.7rem; }
      .container > .inner .team-registrant-card-showedit-wrapper .card-body {
        border: none;
        color: #000000; }
        .container > .inner .team-registrant-card-showedit-wrapper .card-body .text-info {
          border-color: #0089cf;
          color: #0089cf; }
    .container > .inner .price-summary {
      font-family: "CirceRegular"; }
      .container > .inner .price-summary table tbody tr, .container > .inner .price-summary table tfoot tr {
        border: 1px solid #000000; }
      .container > .inner .price-summary table tbody td, .container > .inner .price-summary table tfoot td {
        font-size: 18px; }
      .container > .inner .price-summary table tfoot .table-footer-row td {
        font-size: 20px;
        background-color: #0089cf;
        color: #ffffff;
        font-family: "CirceBold"; }
  .container .btn {
    border: 2px solid #0089cf;
    border-radius: 0;
    color: #0089cf;
    font-family: "CirceRegular";
    padding: 20px;
    padding-bottom: 17px; }
    .container .btn:before {
      background-color: #0089cf; }
    .container .btn:hover, .container .btn:focus, .container .btn:active {
      color: #ffffff; }
      .container .btn:hover:before, .container .btn:focus:before, .container .btn:active:before {
        background-color: #0089cf; }
    .container .btn.btn-with-bg {
      background-color: #0089cf;
      color: #ffffff; }
      .container .btn.btn-with-bg:hover {
        color: #0089cf; }
        .container .btn.btn-with-bg:hover:before {
          background-color: #ffffff; }
    .container .btn.btn-red {
      margin-top: 30px;
      background-color: #ed3026;
      color: #ffffff;
      border-color: #ed3026; }
      .container .btn.btn-red:hover {
        color: #ffffff;
        border-color: #c3271e; }
        .container .btn.btn-red:hover:before {
          background-color: #c3271e; }
      .container .btn.btn-red:focus, .container .btn.btn-red:active {
        color: #ffffff;
        border-color: #E41B13; }
        .container .btn.btn-red:focus:before, .container .btn.btn-red:active:before {
          background-color: #E41B13; }
    @media (max-width: 767px) {
      .container .btn {
        display: block;
        margin-bottom: 15px; } }
  .container .download_wrapper a {
    color: #0089cf; }
  .container .download_wrapper .btn {
    position: relative;
    padding-right: 65px; }
    .container .download_wrapper .btn:after {
      content: '';
      display: inline-block;
      position: absolute;
      z-index: 1;
      background-image: url("../assets/img/down-arrow.png");
      background-repeat: no-repeat;
      background-position: center center;
      width: 30px;
      height: 100%;
      background-size: contain;
      top: 0;
      right: 15px; }

.admin-content-wrapper .container-forms > .inner {
  padding-top: 30px;
  padding-bottom: 30px; }
  @media (max-width: 991px) {
    .admin-content-wrapper .container-forms > .inner h3 {
      padding-top: 0 !important; } }

@media (max-width: 991px) {
  .admin-content-wrapper .container-forms .admin-individual .form-group .admin-buttons {
    margin-bottom: 20px;
    margin-right: 10px;
    float: left;
    display: inline-block; } }

.admin-content-wrapper .table td, .admin-content-wrapper .table th {
  padding: 0.5rem 0.2rem;
  font-size: 17px; }

.login-content .promo-banner-wrapper {
  width: 100%;
  display: block; }
  .login-content .promo-banner-wrapper .promo-banner {
    display: block;
    max-width: 800px;
    margin: 0 auto;
    margin-bottom: 30px;
    width: 100%; }

/*# sourceMappingURL=custom-app.css.map */